import { defineStore } from "pinia";
import { usePwaStore } from "@/stores/pwa";
import { useAuthStore } from "@/stores/auth";
import axios from "@/plugins/axios";
import type { PlotSurvey } from "@/types/survey";
import Bugsnag from "@bugsnag/js";
import SurveyService from "@/services/SurveyService";
import { useAlertStore } from "@/stores/alert";

/**
 * A store module for managing the woodland survey resource.
 *
 * In the following, when interacting with the API, errors are handled by:
 * 1. setting requireSync to true to recover via syncing
 * 2. rethrow the error for console output and for BugSnag
 */
export const usePlotSurveyStore = defineStore("plotSurvey", () => {
	/**
	 * Store a client by calling the API client store endpoint.
	 */
	const store = async function (plotSurvey: PlotSurvey): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		plotSurvey.created_at = isoString;

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			console.log("trying to add new plot to DB...");
			await axios.post(route("api.plot_survey.store"), plotSurvey).then((response)=>{
				const plotSurveyReturned = response.data.data;

				// gets the synced_at version tomatch the DB
				plotSurvey.synced_at = plotSurveyReturned.synced_at;
			}).catch(error=>{
				console.error("create plot failed");

				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}

		// Update the current user store
		for (const client of authStore.user.clients) {
			for (const estate of client.estates) {
				for (const site of estate.sites) {
					const woodlandSurvey = site.woodland_surveys.find((x: any) => x.id == plotSurvey.woodland_survey_id);
					if (woodlandSurvey) {
						woodlandSurvey.plot_surveys.push(plotSurvey);
						break;
					}
				}
			}
		}

	};

	/**
	 * Update a WoodlandSurvey by calling the API WoodlandSurvey update endpoint.
	 */
	const update = async function (plotSurvey: PlotSurvey): Promise<{survey: PlotSurvey, goBack: boolean}> {
		const pwaStore = usePwaStore();
		const alertStore = useAlertStore();
		const surveyService = new SurveyService();
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		let goBack = false;

		plotSurvey.updated_at = isoString;
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {

			await surveyService.updatePlotSurvey(plotSurvey)
				.then((response) => {
					const plotSurveyReturned = response.data.data;
					if (response.data.code == 422) {
						console.warn("(1) Data in the database is newer than this Plot Survey. Could not overwrite. Data has now been synced for this Plot Survey but we recommend manually syncing your account. (Click on your name, then 'manually sync'.");
						alertStore.info("Plot data in the database is newer than this Woodland  Survey. Data has now been synced for this woodland survey. Please check that your recent change has been saved. We recommend manually syncing your account. (Click on your name, then 'manually sync)'.");
						updatePlotInStore(plotSurveyReturned);
						plotSurvey = plotSurveyReturned;
						goBack = true;
					}
					// assuming there hasnt been a real error then we want to reset to
					// the version returned by the attempted sync:
					else plotSurvey.synced_at = plotSurveyReturned.synced_at;
					console.log("updated plot survey");
					console.log(plotSurveyReturned.synced_at);
					console.log(plotSurveyReturned);
					return { survey: plotSurveyReturned, goBack: goBack };
				}).catch(error=>{
					console.error(error);
					return { survey: plotSurvey, goBack: goBack };
				});
		}
		else {
			// not online in order to sync so ensure sync is flagged for when we come online
			pwaStore.requireSync = true;
			return { survey: plotSurvey, goBack: goBack };
		}
		return { survey: plotSurvey, goBack: goBack };

	};

	/**
	 * after successfully storing or update, update the store version to the same syn and update values
	 */
	const updatePlotInStore = function (plotSurvey: PlotSurvey): void {
		const pwaStore = usePwaStore();
		const authStore = useAuthStore();
		// Update the current user store
		for (const client of authStore.user.clients) {
			for (const estate of client.estates) {
				for (const site of estate.sites) {
					if (site.woodland_surveys) for (const woodland of site.woodland_surveys) {
						if (woodland.plot_surveys) for (let plot of woodland.plot_surveys) {
							if (plot.id == plotSurvey.id) {
								console.log("FE updated plot from " + plot.synced_at + " to " + plotSurvey.synced_at);
								plot = plotSurvey;
							}
						}
					}
				}
			}
		}
	};

	/**
	 * Destroy a plotSurvey by calling the API plotSurvey destroy endpoint.
	 */
	const destroy = async function (plotSurvey: PlotSurvey): Promise<void> {
		const authStore = useAuthStore();
		const pwaStore = usePwaStore();

		// Mark the plotSurvey as deleted
		const now = new Date();
		const isoString = now.toISOString().replace("T", " ");
		plotSurvey.deleted_at = isoString;
		plotSurvey.updated_at = isoString;

		// Try to persist it
		if (pwaStore.onlineAndConnected && pwaStore.manuallyOnline) {
			await axios.delete(route("api.plot_survey.destroy", { id: plotSurvey.id })).then(() => {
				console.log("deleted plotSurvey");
				// Remove local soft-deleted plotSurvey by refetching data
				authStore.getCurrentUser();
			}).catch(error=>{
				pwaStore.requireSync = true;
				throw error;
			});
		}
		else {
			pwaStore.requireSync = true;
		}
	};

	return { store, update, destroy };
}, {
	persist: {
		// Ensure none of this store is persisted. Clients are persisted via the
		// current user store.
		paths: []
	}
});
