<template>
	<Page
		:back="page.back"
		:breadcrumbs="page.breadcrumbs"
	>
		<template #header>
			<!-- Header content goes here -->
			<nav class="navbar sticky-top navbar-dark bg-light shadow p-0 bg-body z-10">
				<div id="myList" class="w-100">
					<ul class="nav nav-pills nav-justified navbar-fixed-top my-border">
						<li id="stage1" class="small nav-item w-25" name="stage1">
							<a
								:class="'nav-link my-nav-link-inv align-middle ' + ((pill_name=='preassess') ? 'active' : '')"
								data-bs-toggle="tab"
								aria-controls="preassess"
								href="#preassess"
								@click="select_pill('preassess')">
								Stage 1:<br>Desk phase<br>
								<!-- <span class="badge rounded-pill mx-2" :class="'badge-' + (preassess_valid?3:1)" v-text="' '"/> -->
								<span v-if="preassess_valid" :class="pill_name=='preassess' ? 'score-3' : 'text-white'"><i class="bi-check-square"/></span>
								<span v-else :class="pill_name=='preassess' ? 'score-1' : 'text-white'"><i class="bi-x-square"/></span>
							</a>
						</li>
						<li id="stage2" class="small nav-item w-25" name="stage2">
							<a
								:class="'nav-link my-nav-link-inv ' + (props.pill=='menu1' ? 'active' : '')"
								data-bs-toggle="tab" aria-controls="menu1" href="#menu1"
								@click="select_pill('menu1')">
								Stage 2:<br>10m Plot {{ trans("global.what_we_call_plots_and_walk") }}s<br>
								<span v-if="plots_valid" :class="pill_name=='menu1' ? 'score-3' : 'text-white'"><i class="bi-check-square"/></span>
								<span v-else :class="pill_name=='menu1' ? 'score-1' : 'text-white'"><i class="bi-x-square"/></span>
							</a>
						</li>
						<li id="stage3" class="small nav-item w-25" name="stage3">
							<a
								:class="'nav-link my-nav-link-inv ' + (props.pill=='home' ? 'active' : '')"
								data-bs-toggle="tab" aria-controls="home" href="#home"
								@click="select_pill('home')">
								Stage 3:<br> Woodland walk<br>
								<!-- <span class="badge rounded-pill mx-2" :class="'badge-' + (walk_valid?3:1)" v-text="' '"/> -->
								<span v-if="walk_valid" :class="pill_name=='home' ? 'score-3' : 'text-white'"><i class="bi-check-square"/></span>
								<span v-else :class="pill_name=='home' ? 'score-1' : 'text-white'"><i class="bi-x-square"/></span>
							</a>
						</li>
						<!-- <li
							class="small nav-item w-25">
							<a
								id="map_tab"
								:class="'nav-link my-nav-link-inv ' + (props.pill=='maps' ? 'active' : '')"
								data-bs-toggle="tab" aria-controls="maps" href="#maps"
								name="map_tab"
								@click="select_pill('maps')">
								<br>Map<br>
								<span :class="pill_name=='maps' ? 'score-3' : 'text-white'"><i class="bi-pin-map"/></span>
							</a>
						</li> -->
					</ul>
				</div>
			</nav>
		</template>
		<template #body>
			<!-- Body content goes here -->
			<div >
				<!-- Nav tabs -->

				<UiButton :theme="'theme'" :label="'Go to '+ trans('global.what_we_call_whole_thing') + ' summary'" class="my-2 w-100" @click="$router.push(page.back)"/>

				<!-- Tab panes -->
				<div class="tab-content">
					<div id="preassess" name="preassess" class="p-0 tab-pane container p-0" :class="(!props.pill || props.pill =='preassess') ? 'active' : ''" style="border:none;">
						<WSurvey
							:survey-json="preAssessmentSurveyJson"
							:active-survey-id="survey.id+'_pre'"
							:survey-data="preassessment_survey"
							:no-nav="false"
							:no-scroll="false"
							:valid="preassess_valid ? 1 : 0"
							@update-survey="update_preassessment"/>
					</div>

					<div id="home" name="home" class="tab-pane container p-0" :class="props.pill =='home' ? 'active' : ''" style="border:none; ">
						<WSurvey
							:survey-json="woodlandSurveyJson"
							:active-survey-id="survey.id+'_walk'"
							:survey-data="walk_survey"
							:no-nav="false"
							:no-scroll="false"
							:valid="walk_valid ? 1 : 0"
							@update-survey="update_walk"/>
					</div>

					<div id="menu1" name="menu1" class="tab-pane container p-0" :class="props.pill =='menu1' ? 'active' : ''" style="border:none;">
						<div class="px-0">
							<div id="accordionFlushPlots" class="accordion accordion-flush mb-3 mt-2">
								<div class="accordion-item">
									<h2 id="flush-headingPlots" class="accordion-header">
										<button
											class="accordion-button collapsible collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsePlots" aria-expanded="false"
											aria-controls="flush-collapsePlots">
											<i class="bi-info-circle me-2"/>
											Info
										</button>
									</h2>
									<div
										id="flush-collapsePlots" class="accordion-collapse collapse"
										aria-labelledby="flush-headingPlots"
										data-bs-parent="#accordionFlushPlots">
										<div class="accordion-body">
											<br>
											<p>
												You will need to carry out a minimum of <b>5</b> plot {{ trans("global.what_we_call_plots_and_walk") }}s for woodlands smaller than 30ha. For woodlands of 30ha or larger, you will need to carry out a minimum of <b>10</b> plots {{ trans("global.what_we_call_plots_and_walk") }}s.
												{{ survey.type=='wca' ? 'Spread plots through the different habitat types  and areas of younger or more mature woodland' : 'Spread plots to capture variation in the woodland parcel' }}.
											</p>
											<hr>
											<p>
												Use the site map below to navigate around your site. Your live location is only shown when it is available.
												You can click on a plot <em>marker</em> to create a plot <em>assessment</em> at this location.
												<!-- or, alternatively, use the '+ Add plot here' button when GPS information is available to start a plot assessment at your current location. -->
											</p>
											<hr>
											<p>
												Your plots are numbered in the order that they were created. The best way to better identify each plots is by filling in the plot description, which will then be used to title the plot.
												We recommend giving each plot a unique title such as its what3words location.
											</p>
										</div>
									</div>
								</div>
							</div>

							<div id="siteMapAccordion" class="accordion mb-3">
								<div
									id="plotMap"
									class="accordion-item"
									name="plotMap_accordion_item">
									<h2
										id="heading_plotMap"
										class="accordion-header">
										<button
											class="accordion-button collapsed"
											type="button"
											data-bs-toggle="collapse"
											aria-expanded="false"
											data-bs-target="#collapse_plotMap"
											aria-controls="collapse_plotMap">
											<i class="bi-map me-2"/>Site map
										</button>
									</h2>
									<div
										id="collapse_plotMap"
										class="accordion-collapse collapse p-0"
										aria-labelledby="heading_plotMap"
										data-bs-parent="#siteMapAccordion">
										<div class="accordion-body p-0">
											<MapWithMarkers
												:plot-markers="site.plot_markers"
												:coordinates="coordinates"
												:names="markerNames"
												:center="center"
												:markers-editable="true"
												:key-colours="['rgba(255,255,0.7)']"
												:show-live-location="true"
												:show-create-plot-button="true"
												@create-plot="makeNewPlotLonLat"
												@save-plot-markers="savePlotMarkers"
												@delete-plot-marker="deletePlotMarker"
												@edit-plot-marker="editPlotMarker"
											/>
										</div>
									</div>
								</div>
							</div>

							<div id="plotAccordion" class="accordion">
								<div
									v-for="(plot_survey,index) in survey.plot_surveys.filter((x: any) => x.deleted_at == null).sort((a: PlotSurvey, b: PlotSurvey) => (new Date(a['created_at']) > new Date(b['created_at']) ? 1 : -1))"
									:id="plot_survey.id + '_accordion_item'"
									:key="plot_survey.id"
									class="accordion-item"
									:name="plot_survey.id + '_accordion_item'">
									<PlotSurveyWrapper
										:survey-json="plotSurveyJson"
										:survey-data="JSON.parse(plot_survey.survey_data)"
										:parent-survey="survey"
										:active-survey="plot_survey"
										:index="index"
										:collapsed="((!props.item) && index==0) ? true : !(props.item == (plot_survey.id + '_accordion_item'))">
										<WSurvey
											:is-plot="true"
											:index="index"
											:survey-json="plotSurveyJson"
											:active-survey-id="plot_survey.id"
											:survey-data="JSON.parse(plot_survey.survey_data)"
											:no-nav="false"
											:no-scroll="false"
											:valid="plot_valid(plot_survey) ? 1 : 0"
											@update-survey="update_plot"/>
									</PlotSurveyWrapper>
								</div>
							</div>

							<br>
							<Modal
								id="nvc_modal_"
								:has-form="false"
								:title="'Help with NVC (National Vegetation Classification) Communities'"
								@submit="update_nvc" @clear="update_nvc" @close="update_nvc">
								<!-- <div :id="'nvc_modal_'" class="modal fade" tabindex="-1" :aria-labelledby="'nvc_modal'" aria-hidden="true">
									<div class="modal-dialog">
										<div class="modal-content">
											<div class="modal-header">
												<h5 :id="'nvc_modal'" class="modal-title">
													NVC help
												</h5>
												<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"/>
											</div>
											<div class="modal-body"> -->
								<WSurvey
									:survey-json="NVCSurveyJson"
									:active-survey-id="'nvc'"
									:survey-data="null"
									:no-nav="true"
									:no-scroll="true"
									:valid="1"
									@update-survey="update_nvc"/>
								<!-- </div> -->
								<!-- <div class="modal-footer"> -->
								<!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
													{{ trans('global.buttons.close') }}
												</button>-->
								<!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" ">
													{{ trans('global.buttons.submit') }}
												</button> -->
								<!-- </div> -->
								<!-- </div>
									</div>
								</div> -->
							</modal>
						</div>
					</div>
				</div>
			</div>

			<UiButton :theme="'theme'" :label="'Go to '+ trans('global.what_we_call_whole_thing') + ' summary'" class="mt-2 w-100" @click="$router.push(page.back)"/>
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import { onMounted, computed, ref, inject, reactive, watch } from "vue";

	// import "survey-core/defaultV2.min.css";
	import { StylesManager } from "survey-core";
	import WSurvey from "@/views/pages/private/dashboard/surveys/WSurvey.vue";
	import PlotSurveyWrapper from "@/views/pages/private/dashboard/surveys/PlotSurveyWrapper.vue";
	import UiButton from "@/views/components/input/UiButton.vue";
	import Page from "@/views/layouts/Page.vue";

	// StylesManager.applyTheme("defaultV2");

	import { preAssessmentSurveyJson } from "@/Components/preAssessmentSurveyJson";
	import { woodlandSurveyJson } from "@/Components/woodlandSurveyJson";
	import { plotSurveyJson } from "@/Components/plotSurveyJson";
	import { NVCSurveyJson } from "@/Components/NVCSurveyJson";

	// import { useSurveyStore } from "@/stores/survey";
	import { v4 as uuidv4 } from "uuid";

	import { useGlobalStateStore } from "@/stores/global";
	import { useAuthStore } from "@/stores/auth";
	import { usePwaStore } from "@/stores/pwa";
	import { useAlertStore } from "@/stores/alert";
	import { usePlotSurveyStore } from "@/stores/plot";
	import { useSiteStore } from "@/stores/site";
	import { useWoodlandSurveyStore } from "@/stores/woodland";
	import type { WoodlandSurvey } from "@/types/survey";
	import { trans } from "@/helpers/i18n";
	import Modal from "@/views/components/Modal.vue";
	import type { PlotSurvey } from "@/types/survey";
	import type { PlotMarker } from "@/types/plot_marker";
	import type { Site } from "@/types/site";

	import { Model } from "survey-core";

	import MapWithMarkers from "@/views/pages/private/dashboard/MapWithMarkers.vue";
	import OsGridRef, { LatLon } from "geodesy/osgridref.js";
	import { surveyPlotCoordinates, surveyCentre, surveyMarkerNames } from "@/modules/woodlandSurvey";

	const pwaStore = usePwaStore();
	const authStore = useAuthStore();
	const plotSurveyStore = usePlotSurveyStore();
	const woodlandSurveyStore = useWoodlandSurveyStore();
	const alertStore = useAlertStore();
	const siteStore = useSiteStore();

	const globalStateStore = useGlobalStateStore();
	// const surveyStore = useSurveyStore();

	// const props = defineProps<{
	// }>();
	const props = defineProps<{
		surveyId: string,
		siteId: string,
		estateId: string,
		clientId: string,
		pill: string|null,
		item: string|null
	}>();

	// TODO move to onmounted
	let client = (authStore.user.clients.find((x: any) => x.id == props.clientId));
	let estate = (client.estates.find((x: any) => x.id == props.estateId));
	// let site = (estate.sites.find((x: any) => x.id == props.siteId));
	const site = computed<Site>(() => {
		return estate.sites.find((x: any) => x.id == props.siteId);
	});
	// = (site.woodland_surveys.find((x: any) => x.id == props.siteId));
	const survey = computed({
		get () { return getSurveyFromStore(); },
		set (newval) { setSurveyInStore(newval); }
	});
	// let survey = getSurveyFromStore();// let survey = computed(() => {
	// for (const this_client of authStore.user.clients) {
	// 	for (const this_estate of this_client.estates) {
	// 		for (const this_site of this_estate.sites)  {
	// 			for (const surv of this_site.woodland_surveys)
	// 				if (surv.id == props.surveyId) {
	// 					survey =  surv;
	// 					break;
	// 				}
	// 		}
	// 	}
	// }
	// 	console.log("survey not found");
	// 	return null;
	// });
	// // const plot_surveys = ref(survey.value.plot_surveys.filter((x: any) => x.deleted_at == null).sort((a: PlotSurvey, b: PlotSurvey) => (a["created_at"] > b["created_at"] ? 1 : -1)));
	// watch(() => survey, (newVal) => {
	// 	// 	plot_surveys.value = survey.value.plot_surveys.filter((x: any) => x.deleted_at == null).sort((a: PlotSurvey, b: PlotSurvey) => (a["created_at"] > b["created_at"] ? 1 : -1));
	// 	// console.log("ausd");
	// });
	const pill_name = ref("");
	function select_pill (pill_name_in: string) {
		pill_name.value = pill_name_in;
	}
	function init () {
		pill_name.value = props.pill ? props.pill : "preassess";
	}
	init();

	function plot_valid (p: any) {
		// const psurvey = new Model(plotSurveyJson);
		// const pPrevData = p.survey_data;
		// if (pPrevData) {
		// 	const data = JSON.parse(pPrevData);
		// 	psurvey.data = data;
		// }
		// let plotValid = psurvey.validate();
		// return plotValid;
		return true;
	}
	function getSurveyFromStore () {
		for (const this_client of authStore.user.clients) {
			for (const this_estate of this_client.estates) {
				for (const this_site of this_estate.sites) {
					for (const surv of this_site.woodland_surveys)
						if (surv.id == props.surveyId) {
							return surv;
						}
				}
			}
		}
		return null;
	}
	function setSurveyInStore (woodlandSurvey: WoodlandSurvey) {
		for (const this_client of authStore.user.clients) {
			for (const this_estate of this_client.estates) {
				for (const this_site of this_estate.sites) {
					for (let surv of this_site.woodland_surveys)
						if (surv.id == woodlandSurvey.id) {
							surv = woodlandSurvey;
						}
				}
			}
		}
	}

	// watch(() => preassessment_survey, (newSurveyData: any) =>{
	// 	console.log("preassessment_survey");
	// });
	// watch(() => survey, (newSurveyData: any) =>{
	// 	console.log("saw a change to client");
	// 	// recompute consts:

	// 	// client = (authStore.user.clients.find((x: any) => x.id == props.clientId));
	// 	// estate = (client.estates.find((x: any) => x.id == props.estateId));
	// 	// site = (estate.sites.find((x: any) => x.id == props.siteId));
	// 	// survey = site.woodland_surveys.find((x: any) => x.id == props.surveyId);
	// 	// getSurveyFromStore();
	// });

	// function setPreassessmentSurvey () {
	// 	console.log("setting preassessment survey (function)");
	// 	const survey_obj = JSON.parse(survey.value.survey_data);
	// 	let pre_survey_data = {
	// 		survey_type: survey_obj?.survey_type,
	// 		habitat_type: survey_obj?.habitat_type,
	// 		parcel: survey_obj?.parcel,
	// 		question6: survey_obj?.question6,
	// 		question7: survey_obj?.question7,
	// 		question14a: survey_obj?.question14a,
	// 		question14b: survey_obj?.question14b,
	// 		question14c: survey_obj?.question14c,
	// 		q7: survey_obj?.q7,
	// 		q6: survey_obj?.q6,
	// 		q14: survey_obj?.q14,
	// 		q14_broadleaf: survey_obj?.q14_broadleaf,
	// 		q14_conifer: survey_obj?.q14_conifer
	// 	};
	// 	preassessment_survey.value = pre_survey_data;
	// 	return pre_survey_data;
	// }
	// const preassessment_survey = ref({});
	// setPreassessmentSurvey();

	const preassessment_survey = computed({
		get () {
			console.log("getting preassessment survey");
			// return setPreassessmentSurvey();
			const survey_obj = JSON.parse(survey.value.survey_data);
			let pre_survey_data = {
				survey_type: survey_obj?.survey_type,
				habitat_type: survey_obj?.habitat_type,
				parcel: survey_obj?.parcel,
				question6: survey_obj?.question6,
				question7: survey_obj?.question7,
				question14a: survey_obj?.question14a,
				question14b: survey_obj?.question14b,
				question14c: survey_obj?.question14c,
				q7: survey_obj?.q7,
				q6: survey_obj?.q6,
				q14: survey_obj?.q14,
				q14_broadleaf: survey_obj?.q14_broadleaf,
				q14_conifer: survey_obj?.q14_conifer
			};
			return pre_survey_data;
		},
		set (newval) {
			console.log("setting preassessment survey");
			const survey_obj = JSON.parse(survey.value.survey_data);
			survey_obj.survey_type = newval?.survey_type;
			survey_obj.habitat_type = newval?.habitat_type;
			// survey_obj.parcel = newval?.parcel;
			survey_obj.question6 = newval?.question6;
			survey_obj.question7 = newval?.question7;
			survey_obj.question14a = newval?.question14a;
			survey_obj.question14b = newval?.question14b;
			survey_obj.question14c = newval?.question14c;
			survey_obj.q7 = newval?.q7;
			survey_obj.q6 = newval?.q6;
			survey_obj.q14 = newval?.q14;
			survey_obj.q14_broadleaf = newval?.q14_broadleaf;
			survey_obj.q14_conifer = newval?.q14_conifer;
			survey.value.survey_data = JSON.stringify(survey_obj);
		}

	});
	const walk_survey = computed(() => {
		const survey_obj = JSON.parse(site.value.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data);
		let walk_survey_data = {
			question1: survey_obj?.question1,
			question3b: survey_obj?.question3b,
			invasives: survey_obj?.invasives,
			question6: survey_obj?.question6,
			question12: survey_obj?.question12,
			innsnotes: survey_obj?.innsnotes,
			question15: survey_obj?.question15,
			survey_notes: survey_obj?.survey_notes,
			q1: survey_obj?.q1,
			q3LR: survey_obj?.q3LR,
			q3O: survey_obj?.q3O,
			q6: survey_obj?.q6,
			q12: survey_obj?.q12,
			q15: survey_obj?.q15
		};
		return walk_survey_data;
	});
	function get_plot_survey (id: string) {
		const r = JSON.parse(getSurveyFromStore()?.plot_surveys.find((x:any) => x.id == id).survey_data);
		return r;
	}
	function collateSurveyResults () {
		// site.woodland_surveys.find((x: any) => x.id == props.surveyId)
		survey.value.survey_data =
			JSON.stringify({ ...preassessment_survey.value, ...walk_survey.value });
		return;
	}
	function update_nvc () {
		console.log("finished nvc");
	}
	async function update_preassessment (survey_data: any, valid: boolean) {
		const survey_obj = JSON.parse(survey.value.survey_data);
		survey_obj.question6 = survey_data.question6;
		survey_obj.question7 = survey_data.question7;
		survey_obj.question14a = survey_data.question14a;
		survey_obj.question14b = survey_data.question14b;
		survey_obj.question14c = survey_data.question14c;
		survey_obj.q7 = survey_data.q7;
		survey_obj.q6 = survey_data.q6;
		survey_obj.q14 = survey_data.q14;
		survey_obj.q14_broadleaf = survey_data.q14_broadleaf;
		survey_obj.q14_conifer = survey_data.q14_conifer;
		site.value.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data = JSON.stringify(survey_obj);
		await saveSurveyResults();
		preassess_valid.value = valid;
	}
	async function update_walk (survey_data: any, valid: boolean) {
		const survey_obj = JSON.parse(survey.value.survey_data);
		survey_obj.question1 = survey_data.question1;
		survey_obj.question3b = survey_data.question3b;
		survey_obj.innsnotes = survey_data.innsnotes;
		survey_obj.invasives = survey_data.invasives;
		survey_obj.question6 = survey_data.question6;
		survey_obj.question12 = survey_data.question12;
		// survey_obj.question13 = survey_data.question13;
		survey_obj.question15 = survey_data.question15;
		survey_obj.survey_notes = survey_data.survey_notes;
		survey_obj.q1 = survey_data.q1;
		survey_obj.q3LR = survey_data.q3LR;
		survey_obj.q3O = survey_data.q3O;
		survey_obj.q6 = survey_data.q6;
		survey_obj.q12 = survey_data.q12;
		survey_obj.q15 = survey_data.q15;
		site.value.woodland_surveys.find((x: any) => x.id == props.surveyId).survey_data = JSON.stringify(survey_obj);
		await saveSurveyResults();
		walk_valid.value = valid;
	}
	async function update_plot (survey_data: any, valid:boolean, id: string) {
		console.log("running update_plot after emission");
		if (!survey.value) return;

		let this_plot_survey = survey.value.plot_surveys.find((x:any) => x.id == id);
		console.log("found plot");
		if (this_plot_survey) {
			this_plot_survey.survey_data = JSON.stringify(survey_data);
			waitForPlotUpdateAndExecute(this_plot_survey);
		}

	}
	async function waitForPlotUpdateAndExecute (this_plot_survey: any) {
		// Maximum wait time (5s):
		// this is the maximum time we want to wait for the current update to complete
		const maxWaitMs = 4000;
		const startTime = performance.now();

		while (pwaStore.updateInProgress && (performance.now() - startTime) < maxWaitMs) {
			// Check every 100ms, for reasonable efficiency
			await new Promise((resolve) => setTimeout(resolve, 100));
		}
		console.log("waited " + ((performance.now() - startTime) / 1000).toFixed(2) + "s");

		// either we waited the tolerable amount of time, and the update is still going,
		if (!pwaStore.updateInProgress) {
			pwaStore.startUpdate();
			await plotSurveyStore.update(this_plot_survey).then(
				(response) => {
					console.log("update_plot returned: ");
					console.log(response.survey);
					this_plot_survey.synced_at = response.survey.synced_at;
					this_plot_survey.survey_data = response.survey.survey_data;
					// if (response.goBack) console.log("go back a question");
					pwaStore.endUpdate(this_plot_survey.id);
				})
				.catch((error) => {
					console.error(error);
					pwaStore.endUpdate(null);
				});
		}
		// or we broke out of the wait when the last update finished
		else {
			pwaStore.endUpdate(null);
			console.log("timed out updating plot");
			// ⏳ Timed out, set full sync required
			pwaStore.requireSync = true;
			// alert the user that updates are taking longer than usual
			alert("Updates are taking a longer time than usual, consider using the app in offline mode. Don't forget to turn online mode back on if you reach an area of better signal");
		}
	}

	const invalidPlots = computed(() => {
		let plotsValidated = true;
		let invalidPlotStrings = [];
		if (survey.value.plot_surveys.length > 0) {
			let i = 0;
			for (const p of survey.value.plot_surveys) {
				i++;
				const psurvey = new Model(plotSurveyJson);
				const pPrevData = p.survey_data;
				if (pPrevData) {
					const data = JSON.parse(pPrevData);
					psurvey.data = data;
				}
				let plotValid = manually_validate_survey(psurvey);
				plotsValidated = plotsValidated && plotValid;
				if (!plotValid) invalidPlotStrings.push({ name: (JSON.parse(pPrevData)?.location_descriptor ? " - " + JSON.parse(pPrevData)?.location_descriptor : ""), link: p.id + "_accordion_item", created_at: p.created_at });
			}
		}
		else plotsValidated = false;

		return invalidPlotStrings;

	});
	function manually_validate_survey (survey: Model) {
		let hasError = false;
		let currentPage = null;
		let X = true;
		survey.pages.forEach(page => {
			if (!hasError) {
				if (page.visible && !(page.name == "all")) page.questions.forEach(question => {
					if (question.isVisible && question.isRequired &&
						(question.isEmpty() || question.hasErrors()) &&
						(question.renderAs != "image")) {
						hasError = true;
						currentPage = page;
						// Navigate to the first page with an error
						// if (page.name == "all") return null;
						// console.log(page);
						X = false;
					}
				});
			}
		});
		return X;
	}

	async function saveSurveyResults () {
		console.log("called save survey results");
		if (!survey.value) return;

		collateSurveyResults();
		waitForWoodlandUpdateAndExecute ();
	}
	async function waitForWoodlandUpdateAndExecute () {
		// Maximum wait time (5s):
		// this is the maximum time we want to wait for the current update to complete
		const maxWaitMs = 4000;
		const startTime = performance.now();

		while (pwaStore.updateInProgress && (performance.now() - startTime) < maxWaitMs) {
			// Check every 100ms, for reasonable efficiency
			await new Promise((resolve) => setTimeout(resolve, 100));
		}
		console.log("waited " + ((performance.now() - startTime) / 1000).toFixed(2) + "s");

		// either we waited the tolerable amount of time, and the update is still going,
		if (!pwaStore.updateInProgress) {
			pwaStore.startUpdate();
			await woodlandSurveyStore.update(survey.value).then(
				(response) => {
					console.log("saveSurveyResults returned");
					survey.value.synced_at = response.survey.synced_at;
					survey.value.survey_data = response.survey.survey_data;
					// setPreassessmentSurvey();
					// preassessment_survey.value = survey.value;
					if (response.goBack) console.log("go back a question");
					pwaStore.endUpdate(props.surveyId);

				})
				.catch((error) => {
					console.error(error);
					pwaStore.endUpdate(null);
				});
		}
		// or we broke out of the wait when the last update finished
		else {
			pwaStore.endUpdate(null);
			console.log("timed out updating plot");
			// ⏳ Timed out, set full sync required
			pwaStore.requireSync = true;
			// alert the user that updates are taking longer than usual
			alert("Updates are taking a longer time than usual, consider using the app in offline mode. Don't forget to turn online mode back on if you reach an area of better signal");
		}

	}
	const walk_valid = ref(false);
	const preassess_valid = ref(false);
	const plots_valid = computed(() => {
		return (invalidPlots.value.length == 0);
	});

	const page = reactive({

		id: "survey_info_" + survey.value.id,
		back: "/survey/" + survey.value?.id + "/" + site.value.id + "/" + estate.id + "/" + client.id,
		filters: false,
		breadcrumbs: authStore.user.type != "owner" ? [
			{
				name: client.name,
				to: "/client/" + client.id,
				icon: "bi-person-vcard"

			},
			{
				name: estate.name,
				to: "/estate/" + estate.id + "/" + client.id,
				icon: "bi-map"

			},
			{
				name: site.value.name,
				to: "/site/" + site.value.id + "/" + estate.id + "/" + client.id,
				icon: "bi-pin-map"

			},
			{
				name: survey.value.name,
				active: true,
				icon: "bi-clipboard"
			}
		] : [
			{
				name: site.value.name,
				to: "/site/" + site.value.id + "/" + estate.id + "/" + client.id,
				icon: "bi-pin-map"

			},
			{
				name: survey.value.name,
				active: true,
				icon: "bi-clipboard"
			}],
		actions: [
			// {
			// 	id: "delete",
			// 	name: "Delete Survey",
			// 	icon: "bi-trash",
			// 	theme: "none",
			// 	type: "slim-button"
			// },
			// {
			// 	id: "update",
			// 	name: "Edit Details",
			// 	icon: "bi-pencil",
			// 	theme: "none",
			// 	type: "modal",
			// 	data_bs_target: "#updateSurveyModal_" + surveyStore.survey.value.id
			// }
		]
	});

	const lat = computed(() => {
		return position.value.latitude;
	});
	const lon = computed(() => {
		return position.value.longitude;
	});

	const isElementLoading = computed(() => {
		return globalStateStore.loadingElements.plotForm;
	});

	const position = ref({ latitude: 0, longitude: 0 });
	let error:any = ref(null);

	onMounted(() => {
		getLocation();

		client = (authStore.user.clients.find((x: any) => x.id == props.clientId));
		estate = (client.estates.find((x: any) => x.id == props.estateId));
		// site.value = (estate.sites.find((x: any) => x.id == props.siteId));

		// let survey: WoodlandSurvey;// = (site.woodland_surveys.find((x: any) => x.id == props.siteId));
		// let survey = computed(() => {
		// for (const this_client of authStore.user.clients) {
		// 	for (const this_estate of this_client.estates) {
		// 		for (const this_site of this_estate.sites) {
		// 			for (const surv of this_site.woodland_surveys)
		// 				if (surv.id == props.surveyId) {
		// 					survey.value = surv;
		// 					break;
		// 				}
		// 		}
		// 	}
		// }
	});

	// const projection = ref("EPSG:3857");
	const projection = ref("EPSG:4326");
	const zoom = ref(15);
	const rotation = ref(0);

	const format = inject("ol-format");
	const geoJson = new format.GeoJSON();

	const webglPointsStyle = {
		"circle-radius": 6,
		"circle-fill-color": "yellow",
		"circle-stroke-width": 2,
		"circle-stroke-color": "darkblue",
		"circle-opacity": [
			"interpolate",
			["linear"],
			["get", "population"],
			40000,
			0.6,
			2000000,
			0.92
		]
	};
	const center = computed(() => {
		return surveyCentre(survey.value, site.value);
	});
	const coordinates = computed(() => {
		return surveyPlotCoordinates(survey.value, "rgba(255,255,0.7)");
	});
	const markerNames = computed(() => {
		return surveyMarkerNames(survey.value, false);
	});
	function addPlotMarker (lon: number, lat: number) {
		const pm: PlotMarker = {
			id: uuidv4(),
			site_id: site.value.id,
			longitude: lon,
			latitude: lat
		};
		site.value.plot_markers.push(pm);
	}
	async function savePlotMarkers (markers: any) {
		markers.forEach((coord: Array<any>) => {
			addPlotMarker(coord[0], coord[1]);
		});
		await siteStore.update(site.value);
	}
	async function deletePlotMarker (uuid: string) {
		site.value.plot_markers = site.value.plot_markers.filter((m: PlotMarker) => m.id != uuid);
		await siteStore.update(site.value);
	}
	async function editPlotMarker (uuid: string, lon: number, lat: number) {
		deletePlotMarker(uuid);
		addPlotMarker(lon, lat);
		await siteStore.update(site.value);
	}

	function getLocation () {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					// Handle success
					position.value = {
						latitude: pos.coords.latitude,
						longitude: pos.coords.longitude
					};
				},
				(err) => {
					position.value = { latitude: 0, longitude: 0 };
					if (err.code === err.PERMISSION_DENIED) {
						// alert("Location access denied. Please enable location services in Settings.");
						console.error("Location access denied. Please enable location services in Settings.");
						error.value = "Location access denied. Please enable location services in Settings.";
					} else {
						console.error("Error occurred while getting location:", err);
						error.value = "Error occurred while getting location: " + err;
					}
				},
				{
					enableHighAccuracy: true,
					timeout: 10000,
					maximumAge: 0
				}
			);
		}
		else {
			console.error("Geolocation is not supported by your browser");
			return;
			// alert("Geolocation is not supported by your browser.");
		}

	}
	// call getLocation every 1 seconds to keep it updated on live map
	const seconds = 1;
	const intervalID = setInterval(async () => {
		getLocation();
	}, seconds * 1000);

	async function makeNewPlot () {
		makeNewPlotLonLat(position.value.longitude, position.value.latitude);
	}

	async function makeNewPlotLonLat (longitude: number, latitude: number) {
		console.log(typeof longitude);

		globalStateStore.loadingElements.plotForm = true;
		await getLocation();
		const plot_survey: PlotSurvey = {
			id: uuidv4(),
			created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
			updated_at: new Date().toISOString().slice(0, 19).replace("T", " "),
			woodland_survey_id: survey.value.id,
			survey_data:
				"{\"survey_type\":\"" + JSON.parse(survey.value.survey_data).survey_type + "\"" +
				(latitude == 0 ? "" : ",\"question10lat\":" + String(latitude)) +
				(longitude == 0 ? "" : ",\"question10lon\":" + String(longitude)) +
				// ",\"question3_1\":-5,\"question3_2\":-5,\"question3_3\":-5,\"question3_4\":-5,\"question3_5\":-5,\"question3_6\":-5,\"question3_7\":-5,\"question3_8\":-5" +
				// ",\"question5\":-5" +
				// ",\"question9a\":-5" +
				",\"question0x\":\"gps\",\"q1\":\"b\",\"q2\":false,\"q3LR\":false,\"q3O\":false,\"q4\":0,\"q5\":0,\"q6\":0,\"q8\":0,\"q10a\":false,\"q10b\":false,\"q11\":0,\"q13\":0,\"result\":0}"

			// survey_data: null
		};

		pwaStore.startUpdate();
		await plotSurveyStore.store(plot_survey)
			.then(() => {
				pwaStore.endUpdate(plot_survey.id);
			}).catch((eror) => {
				pwaStore.endUpdate(null);
			});
		globalStateStore.loadingElements.plotForm = false;
	}

</script>
<style>.grid-item { width: 200px; }
.grid-item--width2 { width: 400px; }</style>
