import { ref } from "vue";
import { defineStore } from "pinia";
import type { BeforeInstallPromptEvent } from "@/types/events";

export const usePwaStore = defineStore("pwa", () => {
	// The PWA install event, which is captired and stored here so that we can
	// control the install process.
	const event = ref<BeforeInstallPromptEvent | undefined>(undefined);

	// If the service worker detects a new version of the app exists, this flag
	// is set to true and we can use it to prompt the user to update the app.
	const showRefresh = ref(false);

	// If a chatty update fails, signal a full refresh is required
	const requireSync = ref(false);

	// If the user manually sets the app to offline
	const manuallyOnline = ref(true);

	// If there is an update in progress
	const updateInProgress = ref(false);
	const lastUpdateTook = ref("");
	const timerTime = ref(0);
	const updateItemId = ref("");

	// The function to update the app.
	const updateSW = ref<(reloadPage?: boolean | undefined) => Promise<void>>(() => {
		return Promise.reject();
	});

	// A browser can be online (eg connected to WiFi) but not able to use the
	// web (eg WiFi has dead gateway). We use this state to track if the user
	// is both online AND connected.
	const onlineAndConnected = ref(true);

	// An event handler for when the user goes offline.
	function offline () {
		onlineAndConnected.value = false;
	}

	// An event handler for when the user goes offline.
	function online () {
		getOnlineAndConnected();
	}

	// Verify is the browser is both online (has a network connection) and
	// connected (the network connection works)
	function getOnlineAndConnected () {
		fetch("/", { method: "HEAD" }).then((response) => {
			onlineAndConnected.value = navigator.onLine && response.status === 200;
		}).catch(() => {
			onlineAndConnected.value = false;
		});
	}

	// a somewhat verbose approach to iOS detection
	function isThisDeviceRunningiOS () {
		// TODO
		if (["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform)) {
			return true;
		}
		else if (navigator.userAgent.includes("Mac") && "ontouchend" in document) {
			// iPad on iOS 13
			return true;
		}
		else {
			return false;
		}
	}

	function manuallyOffline () {
		return !manuallyOnline.value;
	}

	function startUpdate () {
		updateItemId.value = "";
		updateInProgress.value = true;
		timerTime.value = performance.now();
	}
	function endUpdate (id: string | null, silent = false) {
		lastUpdateTook.value = ((performance.now() - timerTime.value) / 1000).toFixed(2);
		updateInProgress.value = false;
		if (Number(lastUpdateTook.value) > 4 && !silent) alert("Updates are taking a longer time than usual (" + lastUpdateTook.value + "s), consider using the app in offline mode. Don't forget to turn online mode back on if you reach an area of better signal");
		if (id) updateItemId.value = id;

	}
	const runningCalculations = ref(false);
	function startRunningCalculations () { runningCalculations.value = true; }
	function stopRunningCalculations () { runningCalculations.value = false; }

	const lastKnownPosition = ref({ longitude: 0, latitude: 0, accuracy: 5 });

	return {
		event,
		showRefresh,
		requireSync,
		manuallyOffline,
		manuallyOnline,
		updateSW,
		getOnlineAndConnected,
		onlineAndConnected,
		online, offline,
		updateInProgress,
		startUpdate, endUpdate,
		lastUpdateTook,
		updateItemId,
		runningCalculations,
		startRunningCalculations,
		stopRunningCalculations,
		lastKnownPosition };
}, {
	persist: {
		// Ensure none of this store is persisted. We don't want stale updateSW functions
		// that don't match the current service worker.
		paths: ["manuallyOnline"]
	}
});
