<template>
	<div
		class="w-100 mb-0 pb-0 pb-sm-2 pt-1 pt-sm-2 pt-md-3 bg-white
		form-check form-switch container-fluid small d-flex align-content-center
		sm:align-content-left">
		<div class="ms-2">
			<label class="ms-2 form-check-label small" for="mapTypeSwitch">
				<span v-show="isAerial">Aerial</span>
				<span v-show="!isAerial">Roads</span>
			</label>
			<input
				id="mapTypeSwitch" v-model="isAerial"
				class="ms-0 form-check-input" type="checkbox" role="switch"
				@click="isAerial=!isAerial">
		</div>
		<div class=" d-block flex-fill" />

		<div
			:class="markersEditable ? 'd-block' : 'd-none'">
			<span v-if="editingMarkers">Click on the map to set the marker location.</span>
			<UiButton
				v-if="!addingMarkers" type="button" icon="bi-plus" label="Add new plot markers" theme="small"
				:class="'p-2 mb-1 btn-theme'"
				@click="startAddingMarkers"/>
			<UiButton
				v-else
				label="Finish & save plot markers" theme="small"
				icon="bi-stop-fill"
				:class="'p-2 mb-1 btn-theme'"
				@click="finishAddingMarkers"/>
		</div>
	</div>

	<div ref="mapContainer" class="map-container fade my-div" :class="isAerial ? 'd-none' : 'show'"/>
	<div ref="mapContainer2" class="map-container fade my-div" :class="isAerial ? 'show' : 'd-none'"/>

	<div id="key" class="small  container p-2">
		<i class="bi-geo-alt-fill" :style="'color:red'"/>Site centre,
		<div v-for="(c,i) in keyColours" :key="i" class="icon-containerx">
			<i class="bi bi-circle-fill" :style="'color:' + convertRgbToRgba(c, 0.6) + '!important'"/>
			<i class="bi bi-circle" :style="'color:' + convertRgbToRgba(c, 1) + '!important'"/>
		</div>
		Plot survey{{ keyColours.length>1 ? "s" : "" }},
		<div class="icon-container">
			<i class="bi bi-circle-fill"/>
			<i class="bi bi-circle"/>
		</div>
		Plot markers (10m, to scale)<span v-if="showLiveLocation && pwaStore.lastKnownPosition.accuracy < 50000">, <i class="bi-circle-fill small mb-4" :style="'color:rgba(0,0,255,0.5)'"/> Live Location (±{{ (pwaStore.lastKnownPosition.accuracy) }}m)<UiButton label="Focus map" theme="small" icon="" :class="'px-2 mb-0 btn-theme'" @click="centreToLiveLocation()"/></span>
	</div>

	<div id="popup" ref="popupRef" class="ol-popup">
		<p>
			<strong>{{ popupName }}</strong>
		</p>
		<p>
			Longitude: {{ popupLongitude.toFixed(6) }}
			<br>
			Latitude: {{ popupLatitude.toFixed(6) }}
		</p>
		<div
			v-if="showCreatePlotButton && popupButtonsOn"
			class="d-flex pb-2">
			<div class=" d-block flex-fill" />
			<UiButton
				class="btn btn-theme"
				label="Create plot assessment here" theme="small"
				@click="onCreatePlotHere()"/>
		</div>
		<div
			class="d-flex pb-2"
			:class="markersEditable && popupButtonsOn ? 'd-block' : 'd-none'">
			<div class=" d-block flex-fill" />
			<UiButton
				class="btn btn-theme"
				label="Delete" theme="small"
				@click="deleteMarker(popupUUID)"
			/>
			<UiButton
				class="btn btn-theme"
				label="Edit" theme="small"
				@click="startEditingMarker(popupUUID,popupLongitude,popupLatitude)"
			/>
		</div>
	</div>
	<div id="popup2" ref="popupRef2" class="ol-popup">
		<p>
			<strong>{{ popupName }}</strong>
		</p>
		<p>
			Longitude: {{ popupLongitude.toFixed(6) }}
			<br>
			Latitude: {{ popupLatitude.toFixed(6) }}
		</p>
		<div
			v-if="showCreatePlotButton && popupButtonsOn"
			class="d-flex pb-2">
			<div class=" d-block flex-fill" />
			<UiButton
				class="btn btn-theme"
				label="Create plot assessment here" theme="small"
				@click="onCreatePlotHere()"/>
		</div>
		<div
			class="d-flex pb-2"
			:class="markersEditable && popupButtonsOn ? 'd-block' : 'd-none'">
			<div class=" d-block flex-fill" />
			<UiButton
				class="btn btn-theme"
				label="Delete" theme="small"
				@click="deleteMarker(popupUUID)"
			/>
			<UiButton
				class="btn btn-theme"
				label="Edit" theme="small"
				@click="startEditingMarker(popupUUID,popupLongitude,popupLatitude)"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">

	import "ol/ol.css";
	import { ref, watch, onMounted, computed } from "vue";
	import Map from "ol/Map";
	import View from "ol/View";
	import TileLayer from "ol/layer/Tile";
	import ImageTile from "ol/source/ImageTile";
	import OSM from "ol/source/OSM";
	import { Feature } from "ol";
	import { Geometry } from "ol/geom";
	import Point from "ol/geom/Point";
	import type { Coordinate } from "ol/coordinate";

	// import { Coordinate } from "ol/coordinate";
	import { fromLonLat, toLonLat } from "ol/proj";
	import VectorSource from "ol/source/Vector";
	import VectorLayer from "ol/layer/Vector";
	import Circle from "ol/geom/Circle";
	import { Style, Circle as CircleStyle, Fill, Icon, Stroke, RegularShape } from "ol/style";
	import Overlay from "ol/Overlay";
	import { usePwaStore } from "@/stores/pwa";
	import UiButton from "@/views/components/input/UiButton.vue";

	const props = defineProps<{
		plotMarkers: Array<any>
		coordinates: Array<{longitude: number, latitude: number, colour: string}>
		names: Array<string>
		center: Array<number>
		showLiveLocation: boolean
		showCreatePlotButton: boolean
		markersEditable: boolean
		keyColours: Array<string>
	}>();

	const emit = defineEmits(["save-plot-markers", "delete-plot-marker", "edit-plot-marker", "create-plot"]);

	const map = ref<Map | null>(null);
	const map2 = ref<Map | null>(null);
	const activeLocationFeature = ref<Feature | null>(null);
	const accuracyLocationFeature = ref<Feature | null>(null);
	// const markerSource = ref<VectorSource | null>(null);
	const markerSource = new VectorSource({
	// Optionally, set a loader or format if needed
	// loader: myLoaderFunction,
	// format: myFormat,
	});
	// Create the overlay
	let popup = new Overlay({
		element: document.getElementById("popup") as HTMLElement
	});
	let popup2 = new Overlay({
		element: document.getElementById("popup") as HTMLElement
	});
	const popupRef = ref<HTMLElement | null>(null);
	const popupRef2 = ref<HTMLElement | null>(null);
	const isAerial = ref(false);
	const pwaStore = usePwaStore();
	let userMarkers: Array<[number, number]> = [];
	const editingFeature = ref<Feature<Geometry> | null>(null);
	const addingMarkers = ref(false);
	const editingMarkers = ref(false);
	const selectedFeature = ref<Feature<Geometry> | null>(null);

	const popupName = ref("");
	const popupButtonsOn = ref(true);
	const popupLongitude = ref(0);
	const popupLatitude = ref(0);
	const popupUUID = ref("");
	const eventCoordinate = ref([0, 0]);

	const mapContainer = ref<HTMLElement | undefined>(undefined);
	const mapContainer2 = ref<HTMLElement | undefined>(undefined);

	const position = ref({ latitude: 0, longitude: 0, accuracy: 5 });

	let error:any = ref(null);

	const onSave = () => {
		emit("save-plot-markers", userMarkers);
	};
	const onDelete = (uuid: string) => {
		emit("delete-plot-marker", uuid);
	};

	const onEdit = (uuid: string, lon: number, lat: number) => {
		emit("edit-plot-marker", uuid, lon, lat);
	};
	const onCreatePlotHere = () => {
		popup.setPosition(undefined);
		popup2.setPosition(undefined);
		emit("create-plot",	eventCoordinate.value[0], eventCoordinate.value[1]);

	};

	watch(
		() => pwaStore.lastKnownPosition,
		(newLocation) => updateActiveLocation()
	);

	watch(
		() => props.coordinates,
		(newCoordinates) => {
			if (!newCoordinates.length) return;
			updateMarkers();
		},
		{ deep: true }
	);

	watch(
		() => props.plotMarkers,
		(newPlotMarkers) => {
			if (!newPlotMarkers.length) return;
			updateMarkers();
		},
		{ deep: true }
	);

	watch(
		() => props.names,
		(newNames) => {
			if (!newNames) return;
			updateMarkers();
		},
		{ deep: true }
	);

	onMounted(() => {
		getLocation();
		initializeMap();
		updateActiveLocation();
	});
	/**
	 * Handles the user's location (GPS coordinates)
	 */
	function handlePositionUpdate () {
		const accuracy = pwaStore.lastKnownPosition.accuracy;

		console.log(`📍 Latitude: ${pwaStore.lastKnownPosition.latitude}`);
		console.log(`📍 Longitude: ${pwaStore.lastKnownPosition.longitude}`);
		console.log(`📐 Accuracy: ±${accuracy} meters`);
	}

	function initializeMap () {
		const key = "mkH8cCletE9pGdwHlQnn";

		const attributions =
			"<a href=\"https://www.maptiler.com/copyright/\" target=\"_blank\">&copy; MapTiler</a> " +
			"<a href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\">&copy; OpenStreetMap contributors</a>";

		// Create a tile layer for the map
		const tileLayer = new TileLayer({
			source: new OSM()
		});

		// create aerial map
		const aerialLayer = new TileLayer({
			// source: new OSM()
			source: new ImageTile({
				attributions: attributions,
				url: "https://api.maptiler.com/maps/satellite/{z}/{x}/{y}.jpg?key=" + key,
				tileSize: 512,
				maxZoom: 20
			})
		});

		// Create a vector source and layer for the markers
		// markerSource = new VectorSource();
		const markerLayer = new VectorLayer({
			source: markerSource
		});

		// Create a vector source and layer for the active location
		const activeSource = new VectorSource();
		const accuracySource = new VectorSource();
		const coords = fromLonLat([
			pwaStore.lastKnownPosition.longitude,
			pwaStore.lastKnownPosition.latitude
		]);
		activeLocationFeature.value = new Feature({
			geometry: new Point(fromLonLat([pwaStore.lastKnownPosition.longitude, pwaStore.lastKnownPosition.latitude])),
			name: "Current location"
		});
		accuracyLocationFeature.value = new Feature({
			geometry: new Circle(coords, 20),
			name: "Current location"
		});
		// blue dot live location
		activeLocationFeature.value.setStyle(
			new Style({
				image: new CircleStyle({
					radius: 5,
					fill: (position.value.longitude == 0 && position.value.latitude == 0 && position.value.accuracy == 0) ? new Fill({ color: "rgba(125,125,125,0.5)" }) : new Fill({ color: "rgba(0,0,255,0.5)" })
				})
			})
		);
		accuracyLocationFeature.value.setStyle(
			new Style({
				fill: new Fill({
					color: (position.value.longitude == 0 && position.value.latitude == 0 && position.value.accuracy == 0) ? "rgba(125,125,125, 0.2)" : "rgba(0, 150, 255, 0.2)"
					// Light blue accuracy circle, grey if we dont have good positioning
				}),
				stroke: new Stroke({
					color: (position.value.longitude == 0 && position.value.latitude == 0 && position.value.accuracy == 0) ? "rgba(125,125,125, 0.8)" : "rgba(0, 150, 255, 0.8)",
					width: 2
				})
			})
		);
		const activeLayer = new VectorLayer({
			source: activeSource
		});
		const accuracyLayer = new VectorLayer({
			source: accuracySource
		});

		activeSource.addFeature(activeLocationFeature.value as Feature<Geometry>);
		accuracySource.addFeature(accuracyLocationFeature.value as Feature<Geometry>);

		console.log("Features count:", activeSource.getFeatures().length);
		console.log("Features count:", accuracySource.getFeatures().length);

		const view = new View({
			center: fromLonLat([props.center?.[0] ?? 0, props.center?.[1] ?? 0]),
			zoom: 16
		});

		// Create the map
		map.value = new Map({
			target: mapContainer.value,
			layers: [tileLayer, accuracyLayer, activeLayer, markerLayer],
			view: view
		});
		map2.value = new Map({
			target: mapContainer2.value,
			layers: [aerialLayer, accuracyLayer, activeLayer, markerLayer],
			view: view
		});

		// Initialize popup
		initializePopup();

		// Initialize markers
		updateMarkers();

		// Optionally center the map on the active location
		map.value.getView().setCenter(fromLonLat([props.center[0], props.center[1]]));
	}
	function initializePopup () {
		if (!popupRef.value) {
			console.error("Popup element not found!");
			return;
		}
		if (!popupRef2.value) {
			console.error("Popup2 element not found!");
			return;
		}
		// const popupElement = document.getElementById("popup");

		// Create a popup overlay
		popup = new Overlay({
			element: popupRef.value,
			autoPan: true
		});
		popup2 = new Overlay({
			element: popupRef2.value,
			autoPan: true
		});
		map2.value?.addOverlay(popup2);
		map.value?.addOverlay(popup);

		// Add map click event
		map.value?.on("click", (event: any) => {
			onClickEvent(event);
		});
		map2.value?.on("click", (event: any) => {
			onClickEvent(event);
		});
	}
	function onClickEvent (event: any) {
		if (addingMarkers.value) {
			const coordinate = event.coordinate;
			const lonLat1 = map.value?.getView().getProjection().getCode() === "EPSG:3857"
				? toLonLat(coordinate)
				: coordinate;
			const lonLat2 = map2.value?.getView().getProjection().getCode() === "EPSG:3857"
				? toLonLat(coordinate)
				: coordinate;
			addUserMarker(isAerial.value ? lonLat2 : lonLat1);
		} else {

			const feature = isAerial.value ?
				map2.value?.forEachFeatureAtPixel(event.pixel, (feat) => feat) :
				map.value?.forEachFeatureAtPixel(event.pixel, (feat) => feat);

			if (feature !== null && feature !== undefined) {
				const geometry = feature.getGeometry();
				selectedFeature.value = feature as Feature<Point>;
				popupName.value = feature.get("name") || "No information available";
				popupUUID.value = feature.get("uuid") || "No information available";
				let coordinates = [0, 0];
				if (geometry instanceof Point) {
					coordinates = (feature.getGeometry() as Point).getCoordinates();
				}
				else if (geometry instanceof Circle) {
					coordinates = (feature.getGeometry() as Circle).getCenter();
				}
				const lonLat = toLonLat(coordinates);
				popupLongitude.value = lonLat[0];
				popupLatitude.value = lonLat[1];
				popupButtonsOn.value = popupName.value == "Plot marker (10m, to scale)";

				if (coordinates[0] == 0 && coordinates[1] == 0) {
					popup.setPosition(undefined);
					popup2.setPosition(undefined);
				} else {
					popup.setPosition(coordinates);
					popup2.setPosition(coordinates);
				}
				eventCoordinate.value = [lonLat[0], lonLat[1]];
				console.log("setting event coords:", eventCoordinate.value);
			} else {
				popup.setPosition(undefined);
				popup2.setPosition(undefined);
			}
		}
	}
	function deleteMarker (uuid: string) {
		console.log("calling deleteMarker");
		if (selectedFeature.value) {

			markerSource?.removeFeature(selectedFeature.value as Feature);
			// userMarkers = userMarkers.filter((m) => m !== selectedFeature.value);
			popup.setPosition(undefined);
			selectedFeature.value = null;
			onDelete(uuid);
		}
	}
	function startEditingMarker (uuid: string, lon: number, lat: number) {
		if (!selectedFeature.value) return;

		// Close the popup
		popup.setPosition(undefined);

		// alert("Click on a new location to move this marker.");
		// Store the feature being edited
		editingFeature.value = selectedFeature.value;

		editingMarkers.value = true;

		// Listen for next click to update marker position
		const mapClickHandler = (event: any) => {
			const newCoordinates = toLonLat(event.coordinate);

			if (editingFeature.value) {
				// Move existing marker instead of creating a new one
				// editingFeature.value.getGeometry().setCoordinates(fromLonLat(newCoordinates));
				// editingFeature.value.setCoordinates(fromLonLat(newCoordinates));
				if (editingFeature.value) {
					const geometry = editingFeature.value.getGeometry();
					if (geometry instanceof Point) {
						geometry.setCoordinates(fromLonLat(newCoordinates));
					} else {
						console.error("Editing feature does not have a Point geometry.");
					}
				}
				editingFeature.value.set("gps", newCoordinates);
				editingFeature.value = null;
				onEdit(uuid, newCoordinates[0], newCoordinates[1]);
			}

			// Stop listening for clicks after the edit is done
			editingMarkers.value = false;
			map.value?.un("click", mapClickHandler);

		};

		map.value?.once("click", mapClickHandler);

	}
	function updateActiveLocation () {
		const coords = fromLonLat([
			pwaStore.lastKnownPosition.longitude,
			pwaStore.lastKnownPosition.latitude
		]);
		if (activeLocationFeature.value) {
			const geometry = activeLocationFeature.value.getGeometry();
			if (geometry instanceof Point) {
				geometry.setCoordinates(coords);
			} else {
				console.error("Active location feature does not have a Point geometry.");
			}
		}
		if (accuracyLocationFeature.value) {
			const accuracyGeometry = accuracyLocationFeature.value.getGeometry();
			if (accuracyGeometry instanceof Circle) {
				accuracyGeometry.setCenterAndRadius(coords, pwaStore.lastKnownPosition.accuracy);
			} else {
				console.error("Accuracy feature does not have a Point geometry.");
			}

		}
	}
	function centreToLiveLocation () {
		console.log("centering on LLL...");
		map.value?.getView().setCenter(fromLonLat([pwaStore.lastKnownPosition.longitude, pwaStore.lastKnownPosition.latitude]));
		map.value?.getView().setZoom(15);
	}
	function convertRgbToRgba (rgb: string, alpha = 0.5): string {
		// Extract numbers from 'rgb(r,g,b)'
		const match = rgb.match(/\d+/g);

		if (!match || match.length < 3) {
			throw new Error("Invalid RGB format");
		}

		const [r, g, b] = match.map(Number);
		return `rgba(${r},${g},${b},${alpha})`;
	}
	function updateMarkers () {
		// Clear existing markers
		markerSource?.clear();

		// Add new markers for plots
		props.coordinates?.forEach((data: any, ind: any) => {
			const coord = [data.longitude, data.latitude];
			const feature = new Feature({
				geometry: new Circle(fromLonLat(coord), 10),
				name: (props.names ? props.names[ind] : "No name found"),
				markerTypr: "actual"
			});
			// actual plot location yellow circle
			feature.setStyle(
				new Style({
					fill: new Fill({
						color: convertRgbToRgba(data.colour, 0.5)
						// Light blue accuracy circle
					}),
					stroke: new Stroke({
						color: convertRgbToRgba(data.colour, 0.8),
						width: 2
					})
				})
			);
			markerSource?.addFeature(feature);

			const coord2 = [data.longitude, data.latitude];
			const feature2 = new Feature({
				geometry: new Point(fromLonLat(coord)),
				name: (props.names ? props.names[ind] : "No name found"),
				markerTypr: "actual"
			});
			// actual plot location yellow circle
			feature2.setStyle(
				new Style({
					image: new RegularShape({
						points: 4,
						radius: 4,
						radius2: 0,
						angle: Math.PI / 4,
						stroke: new Stroke({ color: data.colour, width: 2 })
					})
				})
			);
			markerSource?.addFeature(feature2);
		});

		// same for center
		if (props.center[0] && props.center[1]) {
			const feature2 = new Feature({
				geometry: new Point(fromLonLat([props.center[0], props.center[1]])),
				name: "Site centre"
			});
			feature2.setStyle(
				new Style({
					image: new Icon({
						src: "/map_marker.png",
						scale: 0.05
					})
				})
			);
			markerSource?.addFeature(feature2);
		}

		updatePlotMarkers();

	}
	function updatePlotMarkers () {
		// Add new markers
		props.plotMarkers.forEach((coord: any, ind: number) => {
			const feature = new Feature({
				geometry: new Circle(fromLonLat([coord.longitude, coord.latitude]), 10),
				name: "Plot marker (10m, to scale)",
				uuid: coord.id,
				gps: coord,
				markerType: "proposed"
			});
			// proposed plot location magenta circle
			feature.setStyle(
				new Style({
					fill: new Fill({
						color: "rgba(139, 0, 255, 0.2)"
					// Light purple 10m circle
					}),
					stroke: new Stroke({
						color: "rgba(139, 0, 255, 0.8)",
						width: 2
					})
				})
			);
			markerSource?.addFeature(feature);
		});
	}
	function addUserMarker (coordinate: any) {
		// const lonLat = toLonLat(coordinate); // Convert from EPSG:3857 to EPSG:4326

		const feature = new Feature({
			geometry: new Point(fromLonLat(coordinate)),
			name: "Proposed Plot",
			gps: coordinate,
			markerType: "editing"
		});

		feature.setStyle(
			new Style({
				image: new CircleStyle({
					radius: 7,
					fill: new Fill({ color: "red" })
				})
			})
		);

		markerSource?.addFeature(feature);
		// Store as valid GPS coordinates
		userMarkers.push(coordinate);
	}
	function startAddingMarkers () {
		popup.setPosition(undefined);
		addingMarkers.value = true;
		// Clear previous markers
		userMarkers.length = 0;
	}
	function finishAddingMarkers () {
		addingMarkers.value = false;
		console.log("Collected GPS Coordinates:");
		console.log(userMarkers);
		onSave();
	}
	function getLocation () {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(pos) => {
					// GPS doesn’t require the internet, but:
					// ✔ If using GPS, the browser keeps the last known location.
					// ✔ If using Wi-Fi or mobile networks, the location may be lost when offline.
					// Handle success
					position.value = {
						latitude: pos.coords.latitude,
						longitude: pos.coords.longitude,
						accuracy: Math.min(500, Math.max(5, Math.ceil(pos.coords.accuracy)))
					};
					// check if ok to update last known position to current position
					if (!(
						(pos.coords.latitude == 0 && pos.coords.longitude == 0)
						|| (pos.coords.latitude === undefined)
						|| (pos.coords.longitude === undefined)
					))
						pwaStore.lastKnownPosition = {
							latitude: pos.coords.latitude,
							longitude: pos.coords.longitude,
							accuracy: Math.min(500, Math.max(5, Math.ceil(pos.coords.accuracy)))
						};
					// handlePositionUpdate();
				},
				(err) => {
					position.value = { latitude: 0, longitude: 0, accuracy: 0 };
					if (err.code === err.PERMISSION_DENIED) {
						// alert("Location access denied. Please enable location services in Settings.");
						console.error("Location access denied. Please enable location services in Settings.");
						error.value = "Location access denied. Please enable location services in Settings.";
					} else {
						console.error("Error occurred while getting location:", err);
						error.value = "Error occurred while getting location: " + err;
					}
				},
				{
					enableHighAccuracy: true,
					maximumAge: Infinity,
					timeout: 10000
				}
			);
		}
		else {
			console.error("Geolocation is not supported by your browser");
			return;
		}

	}
	// call getLocation every 1 seconds to keep it updated on live map
	const seconds = 1;
	const intervalID = setInterval(async () => {
		getLocation();
	}, seconds * 1000);

</script>

<style>
.map-container {
	width: 100%;
	height: 500px;
}
.ol-popup {
	position: absolute;
	background-color: white;
	padding: 10px;
	border: 1px solid #cccccc;
	border-radius: 4px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
	pointer-events: auto;
}

.ol-popup:after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 10px;
	border-width: 10px;
	border-style: solid;
	border-color: white transparent transparent transparent;
}
/* .fade {
	transition: opacity 2s linear!important;
} */

.my-div {
    height: 70vh; /* 80% of viewport height */
}

@media (orientation: portrait) and (max-width: 768px) {
    .my-div {
        height: 30vh; /* 30% of viewport height on portrait mobile */
    }
}

    .icon-containerx {
      position: relative;
      display: inline-block;
      width: 1rem;
      height: 1rem;
    }

    .icon-containerx .bi {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* font-size: 2rem; */
    }
    .icon-container {
      position: relative;
      display: inline-block;
      width: 1rem;
      height: 1rem;
    }

    .icon-container .bi {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      /* font-size: 2rem; */
    }

    .icon-container .bi-circle-fill {
      color: rgba(139, 0, 255, 0.2); /* Light blue fill */
    }

    .icon-container .bi-circle {
      color: rgba(139, 0, 255, 1); /* Darker blue outline */
    }
</style>
