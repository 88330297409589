<template>
	<div v-if="alertStore.hasAlert()" class="mb-3">
		<div v-for="item, index in alertStore.messages" :key="index" class="mb-1 row">
			<div v-if="alertStore" :class="wrapperClass(index)" class="position-relative pt-3" role="alert">
				<template v-if="alertStore.isError(index)">
					<strong class="fw-bold me-2">{{ trans('global.phrases.argh') }}</strong>
					<span class="block sm:inline">{{ Array.isArray(alertStore.messages[index]) ? alertStore.messages[index][0] : alertStore.messages[index] }}</span>
					<span class="position-absolute top-0 end-0 px-1 py-1 cursor-pointer" @click="removeMessage(index)">
						<i class="bi-x-lg me-2"/>
					</span>
				</template>
				<template v-else-if="alertStore.isSuccess(index)">
					<strong class="fw-bold me-2">{{ trans('global.phrases.success') }}</strong>
					<span class="block sm:inline">{{ Array.isArray(alertStore.messages[index]) ? alertStore.messages[index][0] : alertStore.messages[index] }}</span>
					<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage(index)">
						<i class="bi-x me-2"/>
					</span>
				</template>
				<template v-else-if="alertStore.isWarn(index)">
					<strong class="fw-bold me-2">{{ trans('global.phrases.warning') }}</strong>
					<span class="block sm:inline">{{ Array.isArray(alertStore.messages[index]) ? alertStore.messages[index][0] : alertStore.messages[index] }}</span>
					<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage(index)">
						<i class="bi-x me-2"/>
					</span>
				</template>
				<template v-else>
					<span class="block sm:inline">{{ Array.isArray(alertStore.messages[index]) ? alertStore.messages[index][0] : alertStore.messages[index] }}</span>
					<span class="position-absolute top-0 end-0 px-4 py-3 cursor-pointer" @click="removeMessage(index)">
						<i class="bi-x me-2"/>
					</span>
				</template>
			</div>
		</div>
		<div v-if="alertStore.messages.length > 1" class="container-fluid small d-flex align-content-right">
			<div class="d-block flex-fill"/>
			<a class="small href" @click="clearAll()">Clear all messages</a>
		</div>
	</div>
</template>

<script lang="ts" setup>

	import { computed, defineComponent, ref } from "vue";
	import { trans } from "@/helpers/i18n";
	import { useAlertStore } from "@/stores";

	const alertStore = useAlertStore();
	function wrapperClass (index: number) {
		if (alertStore.isSuccess(index)) {
			return "border px-4 py-4 rounded relative text-sm text-success bg-success-subtle border border-success p-2";
		}
		else if (alertStore.isWarn(index)) {
			return "border px-4 py-3 rounded relative text-sm text-warning bg-warning-subtle border border-warning p-2";
		}
		else if (alertStore.isInfo(index)) {
			return "border px-4 py-3 rounded relative text-sm text-primary bg-info-subtle border border-primary p-2";
		}
		else {
			return "border px-4 py-3 rounded relative text-sm text-danger bg-danger-subtle border border-danger p-2";
		}
	}

	// const errorKeys = computed(() => {
	// 	if (!alertStore.messages || getType(alertStore.messages) === "string") {
	// 		return null;
	// 	}
	// 	return Object.keys(alertStore.messages);
	// });

	// function getType (obj) {
	// 	return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
	// }

	// function getMessageError (key) {
	// 	return alertStore.messages[key][0];
	// }

	function removeMessage (index: number) {
		alertStore.clear(index);
	}

	function clearAll () {
		alertStore.clearAll();
	}

</script>
