import { defineStore } from "pinia";
import type { AlertStore } from "../types/stores";

export const useAlertStore = defineStore({
	id: "alert",
	state: (): AlertStore => {
		return {
			messages: [],
			type: []
		};
	},
	actions: {
		success (message: any) {
			// this.clear();
			this.push(message, "success");
			// this.type = "success";
		},
		error (message: any) {
			// this.clear();
			this.push(message, "error");
			// this.type = "error";
		},
		warn (message: any) {
			// this.clear();
			this.push(message, "warn");
			// this.type = "warn";
		},
		info (message: any) {
			// this.clear();
			this.push(message, "info");
			// this.type = "info";
		},
		push (message: any, status: string) {
			if (Array.isArray(message) || (typeof message === "object" && message != null)) {
				for (const i in message) {
					if (!this.messages.includes(i)) {
						this.messages.push(message[i]);
						this.type.push(status);
					}
				}
			} else {
				if (!this.messages.includes(message)) {
					this.messages.push(message);
					this.type.push(status);
				}
			}
		},
		clear (indexToRemove: number) {
			if (indexToRemove > -1 && indexToRemove < this.messages.length) {
				this.messages.splice(indexToRemove, 1);
				this.type.splice(indexToRemove, 1);
			}
		},
		clearAll () {
			this.messages = [];
			this.type = [];
		},
		isSuccess (index: number) {
			return this.type[index] === "success";
		},
		isError (index: number) {
			return this.type[index] === "error";
		},
		isWarn (index: number) {
			return this.type[index] === "warn";
		},
		isInfo (index: number) {
			return this.type[index] === "info";
		},
		hasAlert () {
			return this.messages.length > 0 && this.type.length > 0;
		},
		hasMultiple () {
			return (Array.isArray(this.messages.length) && this.messages.length > 1) || ((typeof this.messages === "object" && this.messages != null)  && Object.keys(this.messages).length > 1);
		}
	}

});
