<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<Page
		v-if="estate && site && siteId == site.id"
		:icon="'bi-pin-map'"
		:title="site.name || 'Unnamed (please edit woodland site details to add name)'"
		:back="page.back"
		:breadcrumbs="page.breadcrumbs"
		:actions="page.actions" @action="onAction">
		<template #body>
			<div class="container-fluid">
				<ModalSiteUpdate :element-id="'updateSiteModal_'+site.id" :site="site"/>
				<p class="px-3">
					You have {{ site.woodland_surveys.length }} {{ assessmentsAsText }} for this site.
				</p>
				<div class="card card-body mb-3 text-muted">
					<p>
						Here you can see the assessments you have created for this site.
					</p>
					<p>
						Use the site map to place plot markers. A 10m plot is then shown, to scale, at this location.
						You can then use the map within your {{ trans('global.what_we_call_whole_thing') }}
						to navigate to these locations when beginning a new plot {{ trans('global.what_we_call_plots_and_walk') }} for this site.
					</p>
					<p>
						The colour of the {{ trans('global.what_we_call_whole_thing') }}'s symbol corresponds to its marker on the site map.
					</p>
				</div>
				<div id="siteMapAccordion" class="accordion">
					<div
						id="siteMap"
						class="accordion-item"
						name="siteMap_accordion_item">
						<h2
							id="heading_siteMap"
							class="accordion-header">
							<button
								class="accordion-button collapsed"
								type="button"
								data-bs-toggle="collapse"
								aria-expanded="false"
								data-bs-target="#collapse_siteMap"
								aria-controls="collapse_siteMap">
								<i class="bi-map me-2"/>Site map
							</button>
						</h2>
						<div
							id="collapse_siteMap"
							class="accordion-collapse collapse p-0"
							aria-labelledby="heading_siteMap"
							data-bs-parent="#siteAccordion">
							<div v-if="site" class="accordion-body p-0">
								<MapWithMarkers
									v-if="markerNames && keyColours && coordinates"
									:plot-markers="site.plot_markers"
									:coordinates="coordinates"
									:names="markerNames"
									:center="center ? center : [0,0]"
									:markers-editable="true"
									:key-colours="keyColours"
									:show-live-location="false"
									:show-create-plot-button="false"
									@save-plot-markers="savePlotMarkers"
									@delete-plot-marker="deletePlotMarker"
									@edit-plot-marker="editPlotMarker"/>
							</div>
						</div>
					</div>
				</div>
				<div v-if="site.woodland_surveys" class="row">
					<CardSurvey
						v-for="(survey,index) in site.woodland_surveys.sort(createCompareFn('assessment_date', 'asc'))" :key="index"
						:survey="survey"
						:site-id="site.id"
						:estate-id="estate.id"
						:client-id="clientId"
						:key-colour="safeColours[index%safeColours.length]"
					/>

					<!-- Button trigger modal: ADD SITE -->
					<div id="card_add" class="col-xl-3 col-sm-6 col-12 g-4 h-100 card-group">
						<div class="card">
							<div
								class="card-body text-center" type="button" data-bs-toggle="modal" :data-bs-target="'#addSurveyModal_'+site.id">
								<div class="media">
									<div class="media-body text-left">
										<h3 ><i class="bi-clipboard-plus" style="font-size: larger;"/></h3>
									</div>
								</div>
							</div>
							<div class="card-footer text-center">
								Add {{ trans('global.what_we_call_whole_thing') }}
							</div>
						</div>
						<ModalSurveyAdd :site="site" :element-id="'addSurveyModal_'+site.id" :estate-id="estateId" :client-id="clientId"/>
					</div>
				</div>
			</div>
		</template>
	</Page>
	<Page v-else>
		<template #body>
			Something went wrong!
		</template>
	</Page>
</template>

<script lang="ts" setup>
	import Page from "@/views/layouts/Page.vue";
	import { useRouter } from "vue-router";
	import { computed, reactive } from "vue";
	import ModalSiteUpdate from "@/views/pages/private/dashboard/partials/ModalSiteUpdate.vue";
	import ModalSurveyAdd from "@/views/pages/private/dashboard/partials/ModalSurveyAdd.vue";
	import { useAuthStore } from "@/stores/auth";
	import CardSurvey from "@/views/pages/private/dashboard/partials/CardSurvey.vue";
	import { createCompareFn } from "@/modules/utils";
	import { useSiteStore } from "@/stores/site";
	import type { Client } from "@/types/client";
	import type { Estate } from "@/types/estate";
	import type { Site } from "@/types/site";
	import { trans } from "@/helpers/i18n";
	import MapWithMarkers from "@/views/pages/private/dashboard/MapWithMarkers.vue";
	import { siteCentre, siteSurveyPlotCoordinates, siteSurveyMarkerNames, safeColours } from "@/modules/woodlandSurvey";
	import type { PlotMarker } from "@/types/plot_marker";
	import { v4 as uuidv4 } from "uuid";

	const props = defineProps<{
		siteId: string,
		estateId: string,
		clientId: string
	}>();

	const authStore = useAuthStore();
	const siteStore = useSiteStore();
	const router = useRouter();

	const client = computed<Client | undefined>(() => {
		if (!authStore.user) return undefined;
		return authStore.user.clients.find((x: any) => x.id == props.clientId);
	});
	const estate = computed<Estate | undefined>(() => {
		if (!client.value) return undefined;
		return client.value.estates.find((x: any) => x.id == props.estateId);
	});
	const site = computed<Site | undefined>(() => {
		if (!estate.value) return undefined;
		return estate.value.sites.find((x: any) => x.id == props.siteId);
	});

	const page = reactive({
		id: "site_info_" + site.value?.id,
		title: site.value?.name,
		back: "/estate/" + estate.value?.id + "/" + client.value?.id,
		filters: false,
		breadcrumbs: authStore.user.type != "owner" ? [
			{
				name: client.value?.name,
				to: "/client/" + client.value?.id,
				icon: "bi-person-vcard"

			},
			{
				name: estate.value?.name,
				to: "/estate/" + estate.value?.id + "/" + client.value?.id,
				icon: "bi-map"

			},
			{
				name: site.value?.name,
				active: true,
				icon: "bi-pin-map"
			}
		] : [
			{
				name: site.value?.name,
				active: true,
				icon: "bi-pin-map"
			}],
		actions: [
			{
				id: "delete",
				name: "Delete Site",
				icon: "bi-trash",
				theme: "none",
				type: "slim-button"
			},
			{
				id: "update",
				name: "Edit Details",
				icon: "bi-pencil",
				theme: "none",
				type: "modal",
				data_bs_target: "#updateSiteModal_" + site.value?.id
			}
		]
	});

	const center = computed(() => {
		if (site.value) return siteCentre(site.value);
		return null;
	});

	function onAction (data: any) {
		switch (data.action.id) {
		case "delete":
			deleteSite();
			break;
		}
	}
	const assessmentsAsText = computed(() => {
		return trans("global.what_we_call_whole_thing") +
			(site.value?.woodland_surveys.length == 1 ? "" : "s");
	});

	const keyColours = computed(() => {
		if (!site.value) return null;
		const numColours = site.value.woodland_surveys.length;
		if (numColours > safeColours.length) numColours == safeColours.length;
		return safeColours.slice(0, numColours);

	});
	const coordinates = computed(() => {
		if (!site.value) return null;
		return siteSurveyPlotCoordinates(site.value);
	});
	const markerNames = computed(() => {
		if (!site.value) return;
		return siteSurveyMarkerNames(site.value);
	});
	function addPlotMarker (lon: number, lat: number) {
		if (!site.value) return;
		const pm: PlotMarker = {
			id: uuidv4(),
			site_id: site.value.id,
			longitude: lon,
			latitude: lat
		};
		site.value.plot_markers.push(pm);
	}
	async function savePlotMarkers (markers: any) {
		if (!site.value) return;
		markers.forEach((coord: Array<any>) => {
			addPlotMarker(coord[0], coord[1]);
		});
		await siteStore.update(site.value);
	}
	async function deletePlotMarker (uuid: string) {
		console.log("yes, calling deleteplotmarker");
		if (!site.value) return;
		site.value.plot_markers = site.value.plot_markers.filter((m) => m.id != uuid);
		await siteStore.update(site.value);
	}
	async function editPlotMarker (uuid: string, lon: number, lat: number) {
		if (!site.value) return;
		deletePlotMarker(uuid);
		addPlotMarker(lon, lat);
		await siteStore.update(site.value);
	}

	// Delete the site and redirect the window to the parent estate
	async function deleteSite () {
		if (!site.value) throw new Error("SiteInfo.deleteSite does not have a site to delete");
		if (!estate.value) throw new Error("SiteInfo.deleteSite does not have an associated estate");
		if (!client.value) throw new Error("SiteInfo.deleteDite does not have an associated site");
		if (confirm("Are you sure you want to to delete this site? All linked surveys will be deleted as well.")) {
			try {
				await siteStore.destroy(site.value);
			} finally {
				router.push({
					name: "estateInfo",
					params: { estateId: estate.value.id, clientId: client.value.id }
				});
			}
		}
	}
</script>
