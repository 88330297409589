<template>
	<Modal
		:id="elementId" :has-form="true" :title="trans('users.labels.add_survey')" @submit="onFormSubmit"
		@clear="onFormClear" @close="onFormClear">
		<UiForm
			:type="'add'" :data-bs-dismiss="formValidated" @submit="onFormSubmit()" @clear="onFormClear"
			@close="onFormClear">
			<RadioInput
				:model-value="form.survey_type" name="survey_type_query"
				:label="trans('global.phrases.assess_what')"
				:options="[{id:'wca',title:trans('global.labels.wc')},{id:'bng',title:trans('global.labels.bng')}]"
				:required="true" @update:model-value="updateFormSurveyType" />

			<div v-if="form.survey_type=='bng'">
				<p>
					<small>
						This assessment is only for use for a Woodland Condition Assessment
						used with the Statutory Biodiversity Metric. The metric assessment should only be completed by a
						competent person who has the knowledge and skills to perform the assessment.</small>
				</p>
				<hr>

				{{ trans('global.phrases.select_planning_authority') }}:
				<select
					v-model="form.planning_authority" class="form-select" :required="false"
					aria-label="Select Planning Authority">
					<option :value="null" />
					<option
						v-for="(char, index) in countiesListBNG" :key="index" :value="char"
						:disabled="Array.from(char)[0] == '-'">
						{{ char }}
					</option>
				</select>
				{{ trans('global.phrases.select_hab_type') }}<span class="text-danger">*</span>:
				<select
					v-model="form.hab_type" class="form-select" aria-label="Default select example" :required="true"
					:title="trans('global.phrases.select_hab_type')">
					<option v-for="(e,index) in 11" :key="index" :value="String(index)">
						{{ trans('global.report.habitat_type_' + String(index)) }}
					</option>
				</select>

				{{ trans('global.phrases.select_parcel') }}<span class="text-danger">*</span>:
				<select
					v-if="form.survey_type=='bng'" v-model="form.parcel" class="form-select"
					aria-label="next select example" :required="true" :title="trans('global.phrases.select_parcel')">
					<option v-for="(char, index) in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'" :key="index" :value="char">
						{{ char }}
					</option>
				</select>

				<TextInput
					v-model="form.parcel_name" type="text" :required="false" :label="'Parcel name (optional)'"
					name="parcel_name" class="mb-2" />
			</div>
			<div v-else-if="form.survey_type=='wca'">
				<p>
					<small>
						Woodland Condition Assessment (WCA) for sites with no statutory designation (e.g. not
						designated as a Site of Special Scientific Interest). Use this assessment for carrying out a WCA
						on your wood
						and/or for a WCA as part of a Countryside Stewardship agreement.</small>
				</p>
			</div>
			<div v-if="repeatPlotOptions.length > 1">
				<hr>
				<RadioInput
					:model-value="form.re_use_plot" name="reuse_plots_query"
					label="Do you want to re-use plot locations from previous surveys?" :options="repeatPlotOptions"
					:required="true" @update:model-value="updateFormReusePlot" />
			</div>

			<hr>
			<TextInput
				v-model="form.name" type="text" :required="true" :label="trans('users.labels.survey_name')"
				name="name" class="mb-2" />
			<TextInput
				v-model="form.surveyor_name" type="text" :required="true"
				:label="trans('users.labels.surveyor_name')" name="surveyor_name" class="mb-2" />
			<TextInput
				v-model="form.assessment_date" type="date" :required="true"
				:label="trans('users.labels.assessment_date')" name="startDate" />
			<hr>
			<small>
				<CheckboxInput
					v-model="form.permitPartialShare" type="radio" :required="false"
					:label="'Government agencies are interested in the strategic monitoring of environmental data that you and other users of the WCA app might provide. Do you agree to sharing full grid reference information with government agencies? Note that no personal data (name or email) will be shared.'"
					name="useage-select" class="mb-4"
					:options="[{id:'1', title:'YES, I agree to share full grid reference information with government agencies. [OPTIONAL*]'}]"
					@update:model-value="updateFormPermitPartialShare" />
				<!-- <CheckboxInput
					v-model="form.permitFullShare"
					type="radio"
					:required="false"
					:label="'Do you agree to share accurate location data and key reference numbers with government agencies for official purposes? You may be required to share this data with government agencies to support any official application or engagement.'"
					name="useage-select"
					class="mb-4"
					:options="[{id:'1', title:'YES, I agree to share full grid reference information and key reference numbers with government agencies for official purposes. [REQUIRED IN SOME CIRCUMSTANCES]'}]"
					@update:model-value="updateFormPermitFullShare"/> -->
				<!-- In either case, no personal data (name or email) will be shared.  -->
				* Note that for Countryside Stewardship assessments, sharing this data is mandatory.
				<br>
				<a href="/terms" target="_blank">Click here to see the data sharing and management policy.</a>
			</small>
			<br>
		</UiForm>
	</Modal>
</template>

<script lang="ts" setup>
	import router from "@/router";
	import AuthService from "@/services/AuthService";
	import { trans } from "@/helpers/i18n";
	import { defineComponent, reactive, onMounted, computed, ref } from "vue";
	import { getResponseError } from "@/helpers/api";
	import { useAuthStore } from "@/stores/auth";
	import { useAlertStore } from "@/stores";
	import { usePwaStore } from "@/stores/pwa";
	import { useSurveyStore } from "@/stores/survey";
	import TextInput from "@/views/components/input/TextInput.vue";
	import RadioInput from "@/views/components/input/RadioInput.vue";
	import CheckboxInput from "@/views/components/input/CheckboxInput.vue";
	import Modal from "@/views/components/Modal.vue";
	import UiForm from "@/views/components/UiForm.vue";
	import { v4 as uuidv4 } from "uuid";
	import axios from "@/plugins/axios";
	import type { WoodlandSurvey, PlotSurvey } from "@/types/survey";
	import { woodlandSurveyDefaults } from "@/stores/woodland";
	import { useWoodlandSurveyStore } from "@/stores/woodland";
	import { usePlotSurveyStore } from "@/stores/plot";
	import { JsonObject } from "survey-core";
	import { countiesListBNG } from "@/stores/site";

	const authService = new AuthService();
	const alertStore = useAlertStore();
	const authStore = useAuthStore();
	const pwaStore = usePwaStore();
	const surveyStore = useSurveyStore();
	const woodlandSurveyStore = useWoodlandSurveyStore();
	const plotSurveyStore = usePlotSurveyStore();

	const form = reactive({
		name: "",
		parcel_name: "",
		surveyor_name: "",
		assessment_date: "",
		survey_type: "",
		hab_type: null,
		parcel: null,
		permitFullShare: "1",
		permitPartialShare: "0",
		re_use_plot: { id: "Do not re-use plot locations", title: "Do not re-use plot locations" },
		planning_authority: "null"

	});

	const props = defineProps<{
		site: any;
		elementId: string;
		estateId: string;
		clientId: string;
	}>();

	// const mostRecent = ref(null);
	const re_use_plot = ref({ id: "Do not re-use plot locations", title: "Do not re-use plot locations" });

	onMounted(() => {
		if (!authStore.user) {
			return;
		}
		if (props.site.woodland_surveys.length) {
			const mostRecent = props.site.woodland_surveys.reduce((latest:any, current:any) =>
				new Date(current.assessment_date) > new Date(latest.assessment_date) ? current : latest
			);
			console.log("most recent", mostRecent);
			if (mostRecent) re_use_plot.value = { id: mostRecent.id, title: mostRecent.name };
		}
		onFormClear();
	});

	async function onFormClear () {
		form.name = "";
		form.parcel_name = "";
		form.surveyor_name = "";
		form.assessment_date = "";
		form.survey_type = "";
		form.hab_type = null;
		form.parcel = null;
		form.permitFullShare = "1";
		form.permitPartialShare = "0";
		form.planning_authority = "null";
		form.re_use_plot = re_use_plot.value;
	}

	function validateForm () {
		return (!(form.name == "" ||
			form.surveyor_name == "" ||
			form.assessment_date == "" ||
			form.survey_type == null || form.survey_type == "" ||
			(form.survey_type == "bng" && (form.hab_type == null || form.parcel == null))));
	}
	const formValidated = computed (() => {
		return validateForm();
	});

	const repeatPlotOptions = computed (() => {
		return [...props.site.woodland_surveys, "Do not re-use plot locations"];
	});
	function updateFormReusePlot (value: any) {
		form.re_use_plot = value;
	}
	function updateFormSurveyType (value: any) {
		form.survey_type = value.id;
	}
	function updateFormPermitFullShare (value: any, checked: boolean) {
		form.permitFullShare = checked ? "1" : "0";
		form.permitPartialShare = checked ? "0" : form.permitPartialShare;
	}
	function updateFormPermitPartialShare (value: any, checked: boolean) {
		form.permitPartialShare = checked ? "1" : "0";
		form.permitFullShare = checked ? "0" : form.permitFullShare;
	}

	async function onFormSubmit () {
		if (formValidated.value) {
			const authStore = useAuthStore();

			const woodland_survey: WoodlandSurvey = {
				...woodlandSurveyDefaults,
				name: form.name,
				id: uuidv4(),
				plot_surveys: [],
				created_at: new Date().toISOString().replace("T", " "),
				updated_at: new Date().toISOString().replace("T", " "),
				synced_at: null,
				site_id: props.site.id,
				surveyor_name: form.surveyor_name,
				assessment_date: form.assessment_date,
				complete: 0,
				json: new JsonObject,
				type: form.survey_type,
				permit_full_share: form.permitFullShare == "1" ? 1 : 0,
				permit_partial_share: form.permitPartialShare == "1" ? 1 : 0,
				planning_authority: form.planning_authority ? form.planning_authority : "null",
				survey_data:
					"{\"survey_type\":\"" + form.survey_type +
					"\",\"habitat_type\":" + form.hab_type +
					",\"parcel\":\"" + form.parcel + "\"" +
					",\"parcel_name\":\"" + form.parcel_name + "\"," +
					// "\"question6\":-5," +
					// "\"question23\":-5," +
					// "\"question7\":-5," +
					"\"q1\":0,\"q3LR\":false,\"q3O\":false,\"q6\":0,\"q7\":0,\"q8a\":0,\"q8b\":0,\"q9\":0,\"q12\":0,\"q13\":0,\"q14\":0,\"q15\":0,\"result\":0}"
			};

			await woodlandSurveyStore.store(woodland_survey);

			// if the user has selected to re-use plot locations of a previous survey:
			if (form.re_use_plot.id != "Do not re-use plot locations") {
				const old_woodland_survey = props.site.woodland_surveys.find((x: any) => x.id == form.re_use_plot.id);

				for (const old_p of old_woodland_survey.plot_surveys) {
					const descriptor = (JSON.parse(old_p.survey_data).location_descriptor ? JSON.parse(old_p.survey_data).location_descriptor : " ");
					console.log("descriptor");
					console.log(descriptor);
					console.log(JSON.parse(old_p.survey_data));

					const plot_survey: PlotSurvey = {
						// ...plotSurveyDefaults,
						id: uuidv4(),
						created_at: new Date().toISOString().slice(0, 19).replace("T", " "),
						updated_at: new Date().toISOString().slice(0, 19).replace("T", " "),
						woodland_survey_id: woodland_survey.id,
						survey_data:
							"{\"survey_type\":\"" + form.survey_type + "\"" +
							(JSON.parse(old_p.survey_data).question0x ?  ",\"question0x\":\"" + JSON.parse(old_p.survey_data).question0x + "\"" : "\"gps\"") +
							(JSON.parse(old_p.survey_data).question10lat ? ",\"question10lat\":" + JSON.parse(old_p.survey_data).question10lat : "") +
							(JSON.parse(old_p.survey_data).question10lon ? ",\"question10lon\":" + JSON.parse(old_p.survey_data).question10lon : "") +
							(JSON.parse(old_p.survey_data).question12 ? ",\"question12\":\"" + JSON.parse(old_p.survey_data).question12 + "\"" : "") +
							(JSON.parse(old_p.survey_data).location_descriptor ? ",\"location_descriptor\":\"" + descriptor + "\"" : "") +
							",\"q1\":\"b\",\"q2\":false,\"q3LR\":false,\"q3O\":false,\"q4\":0,\"q5\":0,\"q6\":0,\"q8\":0,\"q10a\":false,\"q10b\":false,\"q11\":0,\"q13\":0,\"result\":0}"

						// survey_data: null
					};

					await plotSurveyStore.store(plot_survey);
				}
			}

			router.push("/editsurvey/" + woodland_survey.id + "/" + props.site.id + "/" + props.estateId + "/" + props.clientId);
			onFormClear();
		}
	}

</script>
