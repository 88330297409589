/* eslint-disable no-mixed-spaces-and-tabs */

export const woodlandSurveyJson =
{
	"title": "Survey for the Woodland Walk",
	"logoPosition": "right",
	"pages": [
	 {
	  "name": "page3",
	  "visible": true,
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question20",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\"><div class=\"accordion-item\"><h2 class=\"accordion-header\" id=\"flush-headingOne\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Age distribution of trees</button></h2>" +
						"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>Trees are grouped into classes of <em>young, intermediate</em> and <em>old</em> according to their age. If you are not sure when the trees were planted, you can estimate tree age from the tree's DBH (Diameter at Breast height, recommended that this is 1.3m above ground level)." +
						"<p>Note: Certain broadleaved trees such as birch (Betula), cherry (Prunus) or Sorbus species are typically quicker to reach maturity than other species.</p>" +
						"<p>Birch, cherry or Sorbus species:<ul>\n<li> 0 - 20 years (DBH < 7cm) = <strong>Young</strong></li><li>21 - 60 years (DBH 7-50cm) = <strong>Intermediate</strong></li><li>> 60 years (DBH > 50cm) = <strong>Old</strong></li>\n</ul>\n</p>" +
						"<p>All other trees (including all conifers):<ul><li>0 - 20 years (DBH < 7cm) = <strong>Young</strong></li>\n<li>21 - 150 years (DBH 7-100cm) = <strong>Intermediate</strong></li><li>>150 years (DBh > 1m) = <strong>Old</strong> </li>\n</ul></p>" +
						"<p>For this indicator, ignore any tree or shrub seedlings that have recently germinated.</p>" +
						"</div></div></div></div>"
	   },
	   {
					"type": "checkbox",
					"name": "question1",
					"title": "Across the whole woodland (or woodland parcel), check each age class that is present:",
					"isRequired": true,
					"showNoneItem": true,
					"choices": [
		 {
		  "value": "young",
		  "text": "Young"
		 },
		 {
		  "value": "intermediate",
		  "text": "Intermediate"
		 },
		 {
		  "value": "old",
		  "text": "Old"
		 }
					]
	   }
	  ],
	  "title": "1|A Age distribution of trees"
	 },
	 {
			"name": "page3_invasive",
			"elements": [
		 {
					  "type": "html",
					  "renderAs": "image",
					  "name": "question3a",
					  "html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Invasive non-native plant species (INNS)</button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\"><p>Invasive species cover is calculated as a percentage of the total area of the woodland. These percentages are used to assign a score for each woodland. Note that because Rhododendron and Cherry Laurel are regarded as aggressive colonisers, their presence at any amount leads to an unfavourable score.</p><p>Please also refer to the <a href=\"http://www.nonnativespecies.org/home/index.cfm\" target=\"_blank\">GB non-native species secretariat website</a>.</p></div></div></div></div>"
		 },
		 {
					  "type": "boolean",
					  "name": "question3b",
					  "title": "Are any invasive non-native species present in the woodland?",
					  "defaultValueExpression": "",
					  "isRequired": true
		 },
		 {
					  "type": "imagepicker",
					  "name": "question3c",
					  "visibleIf": "{question3b} = true",
					  "defaultValueExpression": "",
					  "title": "Are any of the following invasive non-native plants present on the woodland walk?",
					  "description": "Check any species that are present within this plot.",
					  "isRequired": true,
					  "valueName": "invasives",
					  "multiSelect": true,
					  "choices": [
		   {
							"value": "lysichiton_americanus",
							"text": "American Skunk Cabbage (Lysichiton Americanus) - Foul-smelling yellow flowers shiny green leaves",
							"imageLink": "/invasive_01_american_skunk.jpg"
		   },
		   {
							"value": "impatiens_glandulifera",
							"text": "Himalayan Balsam (Impatiens Glandulifera) - Hollow stems and pink flowers, dies down in winter",
							"imageLink": "/invasive_02_himalayan_balsam.jpg"
		   },
		   {
							"value": "fallopia_japonica",
							"text": "Japanese knotweed (Reynoutria Japonica) - sRed stems and white flowers, dies down in winter",
							"imageLink": "/invasive_03_japanese_knotweed.jpg"
		   },
		   {
							"value": "prunus_laurocerasus",
							"text": "Cherry Laurel (Prunus Laurocerasus) - Evergreen shrub with shiny green leaves",
							"imageLink": "/invasive_04_laurel.jpg"
		   },
		   {
							"value": "gaultheria_shallon",
							"text": "Shallon (Gaultheria Shallon) - Low-growing woody evergreen, bell-shaped flowers",
							"imageLink": "/invasive_05_shallon.jpg"
		   },
		   {
							"value": "symphoricarpos_albus",
							"text": "Snowberry (Symphoricarpos Albus) - Dense twiggy shrub with abundant white berries",
							"imageLink": "/invasive_06_snowberry.jpg"
		   },
		   {
							"value": "lamiastrum_galeobdolon",
							"text": "Variegated Yellow Archangel (Lamiastrum galeobdolon subsp. Argentatum) - Looks like nettle, but with white-blotched leaves",
							"imageLink": "/invasive_07_archangel.jpg"
		   },
		   {
							"value": "rhododendron_ponticum",
							"text": "Rhododendron (Rhododendron Ponticum) - Evergreen shrub with leathery green leaves",
							"imageLink": "/invasive_08_rhod.jpg"
		   },
		   {
							"value": "other",
							"text": "Other invasive or Schedule 9 species (detail below)",
							"imageLink": "/invasive_other.jpg"
		   }
					  ],
					  "colCount": 1,
					  "imageFit": "cover",
					  "minImageHeight": 105,
					  "maxImageWidth": 200,
					  "maxImageHeight": 105,
					  "showLabel": true
		 },
		 {
					  "visibleIf": "{question3b} and ({invasives} anyof ['other'])",
					  "type": "comment",
					  "name": "innsnotes",
					  "title": "Indicate any other invasive or Schedule 9 species:"
		 }
			],
			"title": "3|C Invasive non-native plant species"
	 },
	 {
	  "name": "page5",
	  "visible": true,
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question23",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">" +
						"<div class=\"accordion-item\">" +
						"<h2 class=\"accordion-header\" id=\"flush-headingOne\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Open space within woodland</button></h2>" +
						"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>Use maps and aerial photographs to estimate what % of Woodland area is open space. </p>" +
						"<p>This is <em>temporary open space</em> in which trees can be expected to regenerate (e.g. glades, rides, footpaths, areas " +
							"of clear-fell). This differs from <em>permanent open space</em> where tree regeneration is not possible or desirable (e.g. " +
							"tarmac, buildings, rivers). The area of open space must be at least 10m wide with less than 20% covered by shrubs or trees.</p>" +
					// "<p>In order " +
					// "to reach good condition, transitions between areas of more open space and more closed canopy woodland " +
					// "should be graded ecotones, with transitions not clearly delineated. Exemptions for small woodlands in indicator " +
					// "description are due to the increased ratio of edge habitat to woodland where the woodland is <10ha.</p>" +
						"<p>You will be asked to verify this answer after the woodland walk.</p>" +
						"<p>You may find the CanopyCover (CanopyCapture on Windows) application (<a href=\"https://play.google.com/store/apps/details?id=com.heaslon.canopycover\" target=\"_blank\">Android</a>, <a href=\"https://appsonwindows.com/apk/5197531/\" target=\"_blank\">Windows</a>) useful for this question.</p>" +
						"</div>\n</div>\n</div>\n</div>"
	   },
	   {
					"type": "nouislider",
					"name": "question6",
					"isRequired": true,
					"title": "Verify the percentage of the woodland (or woodland parcel) that is open space:",
					"description": "This is based on your answer to this question in Stage 1. If you have not completed this question in stage 1, either enter it now, or return to stage 1 when you return to your desk.",
					"inputType": "range",
					"rangeMax": 100,
					"step": 1
	   }
	  ],
	  "title": "6|F Open space within woodland"
	 },
	 {
			"name": "page9",
			"visible": true,
			"elements": [
		 {
		  "type": "html",
		  "renderAs": "image",
		  "name": "question29",
		  "html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\">Veteran trees</button></h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
		  "<p>Veteran trees are not always old trees, but should be alive. They are defined either by DBH (Diameter at Breast Height, recommended that this is 1.3m above ground level) for a given species, and/or three or more features and attributes combined. These include:\n<ul>\n<li>Major trunk cavities or hollowing</li>\n<li>Water pools in tree crevices</li>\n<li>Small holes in the trunk, larger branches or larger roots caused by decay</li>\n<li>Missing or loose bark</li>\n<li>Large quantities of dead wood in the canopy</li>\n<li>Areas where sap is seeping through the bark</li>\n<li>Crevices sheltered from direct rainfall</li>\n<li>Fungi on the trunk or larger branches</li>\n<li>Plants growing on the trunk or branches (not including mosses or lichens).</li>\n</ul>The features listed are not exhaustive. Aim to record all veteran trees on the <a href=\"https://ati.woodlandtrust.org.uk \">Ancient Tree Inventory</a>.\n</p>\n\n</div></div></div></div>"
		 },
		 {
		  "type": "text",
		  "name": "question12",
		  "title": "Enter the number of veteran trees noted on the woodland walk",
		  "isRequired": true,
		  "defaultValueExpression": "",
		  "inputType": "number",
		  "min": 0,
		  "step": 1
		 }
			],
			"title": "12|K Veteran trees"
	 },
	 {
	  "name": "page12",
	  "visible": true,
	  "elements": [
	   {
					"type": "html",
					"name": "question32",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">\n  <div class=\"accordion-item\">\n    <h2 class=\"accordion-header\" id=\"flush-headingOne\">\n      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>\n        Woodland disturbance\n      </button>\n    </h2>\n    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\">\n\n<div class=\"accordion-body\">" +
						"<p>Examples of disturbance are: significant nutrient enrichment; soil compaction from trampling, machinery, animal " +
						"poaching or litter.</p>" +
						"<p>Record significant patches (>0.1ha) of nettle and/or cleavers which can indicate significant nutrient enrichment. Also note soil that has been damaged (e.g. deep ruts) and/or excessive or continuous compaction (e.g. by forestry machinery, animal poaching etc.). Some soil disturbance by animals is necessary and desirable for ecological functioning in woodland.</p>\n\n</div> </div></div></div>"
	   },
	   {
					"type": "checkbox",
					"name": "question15",
					"isRequired": true,
					"showNoneItem": true,
					"title": "Choose all statements that describe nutrient enrichment and soil damage across the woodland",
					"choices": [
		 {
		  "value": "significant",
		  "text": "There is evidence of significant nutrient enrichment across the woodland"
		 },
		 {
		  "value": ">1ha",
		  "text": "A patch (or sum of patches) of nutrient enrichment exceeds one hectare (or, for woodlands <1ha, the whole woodland evidences nutrient enrichment)"
		 },
		 {
		  "value": "damage",
		  "text": "There is evidence of damaged ground across the woodland"
		 },
		 {
		  "value": "damage>20%",
		  "text": "More than 20% of woodland area has soil damage"
		 }
					]
	   }
	  ],
	  "title": "15|M Woodland disturbance"
	 },
	 {
	  "name": "notespage",
	  "visible": true,
	  "elements": [
	   {
					"type": "comment",
					"name": "survey_notes",
					"title": "Please use this section to add any further survey notes for this assessment:"
	   }
	  ],
	  "title": "Notes"
	 }
	],
	"calculatedValues": [
	 {
	  "name": "survey_type",
	  "expression": "{survey_type}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "habitat_type",
	  "expression": "{habitat_type}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q1",
	  "expression": "count({question1})",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q3LR",
	  "expression": "iif(count({invasives})==0, false, iif({invasives} contains 'rhododendron_ponticum' or {invasives} contains 'prunus_laurocerasus',true,false))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q3O",
	  "expression": "iif(count({invasives})==0, false, iif({invasives} contains 'lysichiton_americanus' or {invasives} contains 'impatiens_glandulifera' or {invasives} contains 'fallopia_japonica' or {invasives} contains 'gaultheria_shallon' or {invasives} contains 'symphoricarpos_albus' or {invasives} contains 'lamiastrum_galeobdolon',true,false))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q6",
	  "expression": "iif({question6}==-5,0,iif({question6}<10,1,iif({question6}<20,3, iif({question6}<40,2,1))))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q12",
	  "expression": "{question12}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q15",
	  "expression": "iif({question15} contains 'none', 3, iif(count({question15})==0,0,iif({question15} contains '>1ha' or {question15} contains 'damage>20%',1,2)))",
	  "includeIntoResult": true
	 }
	],
	"sendResultOnPageNext": true,
	"showTitle": false,
	"showProgressBar": "bottom",
	// "showCompletedPage": true,
	"showPreviewPage": true,
	"checkErrorsMode": "onComplete",
	"pageNextText": "Save & continue",
	"completeText": "Save",
	"showPreviewBeforeComplete": "showAllQuestions",
	"widthMode": "responsive",
	"fitToContainer": true,
	"showQuestionNumbers": "off",
	// "showNavigationButtons": "bottom",
	"previewText": "Save & preview",
	"completedHtml": "Until you have submitted your survey, you will still be able to edit these answers. Simply return to this page.",
	"showCompletedPage": false,
	"showPrevButton": false,
	"showNavigationButtons": "none"
};
