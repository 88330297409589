<template>
	<div
		class="h-sm-100 d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start text-white w-100">
		<router-link
			v-slot="{ navigate }" to="/about"
			class="px-2 pt-2 w-100 border-0 text-white link-underline link-underline-opacity-0">
			<div class="d-flex align-items-center pb-2 mb-2" @click="navigate">
				<!-- <img src="/favicon.svg" :alt="state.app.name" width="75" height="75"> -->
				<h3>
					Woodland
					Condition
					Assessment
					<span style="color:red">{{ appEdition }}</span>
				</h3>
			</div>
		</router-link>
		<ul
			id="menu"
			class="border-top w-100 d-none d-sm-flex px-sm-2 nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start">
			<br>
			<WcaMenu :state="state" :type="'desktop'" />
		</ul>
		<ul
			id="menu"
			class="d-sm-none px-sm-2 nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start">
			<WcaMenu :state="state" :type="'mobile'" />
		</ul>
		<div class="flex-grow-1"/>
		<!-- <div v-if="pwaStore.updateInProgress" class="px-2 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1 sm-bg-theme-800 py-3 my-p-0 w-sm-100">
			<span class="px-2 d-flex align-items-center text-white text-decoration-none">
				<i class="bi bi-arrow-repeat me-2"/>
				Syncing...
			</span>
		</div> -->
		<div
			class="dropdown px-2 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1 sm-bg-theme-800 py-3 my-p-0 w-sm-100">
			<a
				id="dropdownUser1" data-test-id="dropdownUser1" href="#"
				class="px-2 d-flex align-items-center text-white text-decoration-none dropdown-toggle"
				data-bs-toggle="dropdown" aria-expanded="false"
				@click="state.isAccountDropdownOpen = !state.isAccountDropdownOpen">
				<i v-if="!pwaStore.updateInProgress" class="bi bi-circle-fill" :class="(pwaStore.onlineAndConnected && pwaStore.manuallyOnline) ?'online':'offline'" />
				<i v-else class="bi bi-arrow-repeat"/>

				<span class="d-none d-sm-inline mx-1 d-inline-block text-truncate">{{
					authStore.user.full_name }}</span>

				<span v-if="pwaStore.event || pwaStore.showRefresh" class="badge bg-secondary">{{
					(pwaStore.event?1:0) + (pwaStore.showRefresh?1:0) }}</span>
			</a>
			<ul class="dropdown-menu  text-small shadow" aria-labelledby="dropdownUser1">
				<li class="dropdown-item">
					<router-link
						to="/panel/profile"
						class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80">
						{{ trans('global.pages.profile') }}
					</router-link>
				</li>
				<li class="dropdown-item">
					<a
						href="#"
						class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
						data-test-id="logout-link" @click.prevent="onLogout">
						{{ trans('global.phrases.sign_out') }}
					</a>
				</li>
				<li>
					<hr class="dropdown-divider">
				</li>
				<li class="dropdown-item">
					<a
						class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
						:class="!(pwaStore.onlineAndConnected && pwaStore.manuallyOnline) ? 'disabled' :''"
						@click="sync">
						Manually sync data
					</a>
				</li>
				<li>
					<hr class="dropdown-divider">
				</li>
				<li class="dropdown-item">
					<div v-if="pwaStore.showRefresh">
						New version available:
						<button @click="pwaStore.updateSW(true)">
							Update
							<span
								class="position-absolute top-3 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
								<span class="visually-hidden">New alerts</span>
							</span>
						</button>
					</div>
					<div v-else-if="pwaStore.event">
						<button
							class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
							@click="install">
							Install App<span class="badge bg-danger" />
							<!-- <span class="position-absolute top-3 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
								<span class="visually-hidden">New alerts</span>
							</span> -->
						</button>
					</div>
					<!-- <div v-else class="dropdown-item disabled">
						<a class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80">
							Latest version (or cannot update)
						</a>
					</div> -->
					<div v-else>
						<button
							class="dropdown-item block px-4 py-2 hover-bg-theme-800 hover:text-white hover:opacity-80"
							@click="router.push('/help')">
							Download Instructions
							<!-- <span class="position-absolute top-3 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
								<span class="visually-hidden">New alerts</span>
							</span> -->
						</button>
					</div>
				</li>
				<li>
					<hr class="dropdown-divider pb-0">
				</li>
				<li class="dropdown-item disabled py-0">
					<span class="dropdown-item block px-4 pt-0 py-0 fst-italic text-muted small">
						Version 2.0.1 ({{ commitHash }})
					</span>
				</li>
			</ul>
		</div>
		<footer class="d-none d-sm-block flex-shrink-1 w-100 text-muted">
			<OnlineOfflineSlider/>
		</footer>
	</div>
	<div class="d-block d-sm-none">
		<OnlineOfflineSlider/>
	</div>
</template>

<script lang="ts" setup>

	import { computed, onBeforeMount, reactive, watch } from "vue";
	import Alert from "@/views/components/Alert.vue";

	import { trans } from "@/helpers/i18n";
	import WcaMenu from "@/views/layouts/WcaMenu.vue";
	import AvatarIcon from "@/views/components/icons/Avatar.vue";
	import { useAuthStore } from "@/stores/auth";
	// import { useGlobalStateStore } from "@/stores/global";
	import { useRoute } from "vue-router";
	import { useAlertStore } from "@/stores/alert";
	import { getAbilitiesForRoute } from "@/helpers/routing";
	import PwaStatus from "@/Components/PwaStatus.vue";
	import { usePwaStore } from "@/stores/pwa";
	import ModalSyncError from "@/views/components/ModalSyncError.vue";
	import router from "@/router";

	import UiButton from "@/views/components/input/UiButton.vue";
	import Modal from "@/views/components/Modal.vue";
	import MainMenu from "@/views/layouts/MainMenu.vue";
	import OnlineOfflineSlider from "@/views/layouts/OnlineOfflineSlider.vue";

	const alertStore = useAlertStore();

	const authStore = useAuthStore();
	// const globalStateStore = useGlobalStateStore();
	const route = useRoute();
	const pwaStore = usePwaStore();
	const commitHash = process.env.VITE_COMMIT_HASH;
	const appEdition = import.meta.env.VITE_APP_EDITION;

	const state = reactive({
		mainMenu: [
			{
				name: trans("global.pages.home"),
				icon: "speedometer2",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false,
				to: "/panel/dashboard"
			},
			{
				name: "ACT_TYPE",
				icon: "grid-3x3-gap-fill",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false,
				to: "/panel/projects"
			},
			{
				name: trans("global.pages.help"),
				icon: "patch-question",
				to: "/help",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false
			},
			{
				name: trans("global.pages.about"),
				icon: "info-circle",
				to: "/about",
				showDesktop: true,
				showMobile: false,
				requiresAbility: false
			}
		],
		isAccountDropdownOpen: false,
		isMobileMenuOpen: false,
		currentExpandedMenuItem: null,
		app: (window as any).AppConfig
	});

	function install () {
		if (pwaStore.event) {
			pwaStore.event.prompt();
		}
	}

	async function sync () {
		authStore.syncCurrentUser(false).then(() =>{
			const currentRoute = router.currentRoute.value;
			console.warn(currentRoute.fullPath);
			// router.go(0);
			// router.push("/refresh");
			router.push(currentRoute.fullPath).then(() => {
				return console.log("done");
			}).catch((error) => console.error(error));
		});

	}
	function onLogout () {
		authStore.logout();
	}
</script>
