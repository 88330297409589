<template>
	<div
		class="w-100 mb-0 pb-0 pb-sm-2 pt-1 pt-sm-2 pt-md-3  bg-white
		form-check form-switch container-fluid small d-flex align-content-center sm:align-content-left">
		<div class=" d-block d-sm-none flex-grow-1" />
		<div class="ms-2">
			<label class="ms-2 form-check-label" for="flexSwitchCheckDisabled">
				<span v-show="pwaStore.manuallyOnline">Online</span>
				<span v-show="!pwaStore.manuallyOnline">Working offline</span>
			</label>
			<input
				id="flexSwitchCheckDisabled" v-model="pwaStore.manuallyOnline"
				class="ms-0 form-check-input" type="checkbox" role="switch"
				:disabled="!pwaStore.onlineAndConnected" @click="updatemanuallyOnline">
		</div>
		<div class=" d-none d-sm-block flex-grow-1" />
		<div>
			<UiButton
				:label="''" :type="'modal'" icon="bi-info-circle"
				:data-bs-target="'#manual_offline_info_modal'" theme="small"
				:class="'p-2 mb-1'" />
		</div>
	</div>
</template>

<script lang="ts" setup>

	import { computed, onBeforeMount, reactive, watch } from "vue";
	import Alert from "@/views/components/Alert.vue";

	import { trans } from "@/helpers/i18n";
	import WcaMenu from "@/views/layouts/WcaMenu.vue";
	import AvatarIcon from "@/views/components/icons/Avatar.vue";
	import { useAuthStore } from "@/stores/auth";
	import { useGlobalStateStore } from "@/stores/global";
	import { useRoute } from "vue-router";
	import { useAlertStore } from "@/stores/alert";
	import { getAbilitiesForRoute } from "@/helpers/routing";
	import PwaStatus from "@/Components/PwaStatus.vue";
	import { usePwaStore } from "@/stores/pwa";
	import ModalSyncError from "@/views/components/ModalSyncError.vue";
	import router from "@/router";

	import UiButton from "@/views/components/input/UiButton.vue";
	import Modal from "@/views/components/Modal.vue";

	const alertStore = useAlertStore();

	const authStore = useAuthStore();
	const globalStateStore = useGlobalStateStore();
	const route = useRoute();
	const pwaStore = usePwaStore();

	// const manuallyOnline = computed(() => { return !pwaStore.manuallyOffline; });
	function updatemanuallyOnline () {
		pwaStore.manuallyOnline = !pwaStore.manuallyOnline;
	}
</script>
