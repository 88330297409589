<template v-if="!(activeSurveyId === null) && survey">
	<div class="m-0 pt-2" style="background-color:var(--sjs-general-backcolor-dim, var(--background-dim, #fff))">
		<div v-if="goToError" class="alert alert-warning my-0 mx-2" role="alert">
			Hint: incorrect or invalid answer in section {{ goToError }}.
		</div>
	</div>
	<SurveyNav
		v-if="!noNav"
		:active-survey-id="activeSurveyId"
		:class="'p-0'"
		:use-title="true"
		:is-valid="goToError ? false : true"
		:is-showing-preview="survey?survey['isShowingPreview']:false"
		:title="survey?.title"
		:description="survey?.description"
		:is-first-page="survey?.isFirstPage"
		:is-last-page="survey?.isLastPage"
		:is-preview="survey.currentPage=='preview-page'"
		:current-page-is-all="survey?.currentPage=='all'"
		:completed-html="survey.completedHtml">
		<div
			id="sv-nav-prev" class="sv-action pe-2" height="100%"
			@click="survey?.prevPage()">
			<button
				type="button" class="sd-btn sd-navigation__prev-btn sd-small-btn"
				:disabled="survey.isFirstPage || pwaStore.updateInProgress">
				<div class="sv-action__content justify-content-center">
					<div class="small">
						<i class="bi bi-arrow-left" />
						<br>
						Go
						<br>
						Back
					</div>
				</div>
			</button>
		</div>
		<div id="sv-nav-preview" class="sv-action px-2" @click="showPreview()">
			<button
				type="button" class="sd-btn sd-navigation__next-btn sd-small-btn"
				:disabled="survey.currentPage=='all' || survey.currentPage=='preview-page' || pwaStore.updateInProgress">
				<div class="sv-action__content justify-content-center">
					<div class="small">
						<i class="bi bi-clipboard-check" />
						<br>
						Save &<br>
						Summary
					</div>
				</div>
			</button>
		</div>
		<div id="sv-nav-next" class="sv-action ps-2" @click="survey.isLastPage ? showPreview() : nextPage()">
			<button
				type="button" class="sd-btn sd-navigation__next-btn sd-small-btn"
				:disabled="survey.currentPage=='preview-page' || pwaStore.updateInProgress">
				<div class="sv-action__content justify-content-center">
					<div class="small">
						<i class="bi bi-arrow-right" />
						<br>
						Save &
						<br>
						Next
					</div>
				</div>
			</button>
		</div>
	</SurveyNav>
	<SurveyComponent
		:id="activeSurveyId"
		:model="survey ? survey :null" />
	<SurveyNav
		v-if="!noNav"
		:survey="survey"
		:active-survey-id="activeSurveyId"
		:class="'p-0'"
		:use-title="false"
		:is-valid="goToError? false : true"
		:is-showing-preview="survey?survey['isShowingPreview']:false"
		:title="survey?.title"
		:description="survey?.description"
		:is-first-page="survey?.isFirstPage"
		:is-last-page="survey?.isLastPage"
		:current-page-is-all="survey?.currentPage=='all'"
		:completed-html="survey.completedHtml"
		:is-preview="survey.currentPage=='preview-page'">
		<div
			id="sv-nav-prev" class="sv-action pe-2" height="100%"
			@click="survey?.prevPage()">
			<button
				type="button" class="sd-btn sd-navigation__prev-btn sd-small-btn"
				:disabled="survey.isFirstPage || pwaStore.updateInProgress">
				<div class="sv-action__content justify-content-center">
					<div class="small">
						<i class="bi bi-arrow-left" />
						<br>
						Go
						<br>
						Back
					</div>
				</div>
			</button>
		</div>
		<div id="sv-nav-preview" class="sv-action px-2" @click="showPreview()">
			<button
				type="button" class="sd-btn sd-navigation__next-btn sd-small-btn"
				:disabled="survey.currentPage=='all' || survey.currentPage=='preview-page' || pwaStore.updateInProgress">
				<div class="sv-action__content justify-content-center">
					<div class="small">
						<i class="bi bi-clipboard-check" />
						<br>
						Save &<br>
						Summary
					</div>
				</div>
			</button>
		</div>
		<div id="sv-nav-next" class="sv-action ps-2" @click="survey.isLastPage ? showPreview() : nextPage()">
			<button
				type="button" class="sd-btn sd-navigation__next-btn sd-small-btn"
				:disabled="survey.currentPage=='preview-page' || pwaStore.updateInProgress">
				<div class="sv-action__content justify-content-center">
					<div class="small">
						<i class="bi bi-arrow-right" />
						<br>
						Save &
						<br>
						Next
					</div>
				</div>
			</button>
		</div>
	</SurveyNav>
</template>

<script lang="ts" setup>
	/** WCA Root Component */
	import { onMounted, watch, computed, shallowRef } from "vue";
	import "survey-core/defaultV2.min.css";
	StylesManager.applyTheme("defaultV2");
	import { StylesManager } from "survey-core";
	import { Model } from "survey-core";
	import { usePwaStore } from "@/stores/pwa";
	import SurveyNav from "@/views/pages/private/dashboard/surveys/SurveyNav.vue";

	import * as SurveyCore from "survey-core";
	// @ts-ignoreCore from "survey-core";
	// @ts-ignore
	import { nouislider } from "surveyjs-widgets";
	import "nouislider/dist/nouislider.css";
	nouislider(SurveyCore);

	import Inputmask from "inputmask";
	// @ts-ignore
	import { inputmask } from "surveyjs-widgets";
	inputmask(SurveyCore);

	const pwaStore = usePwaStore();

	const props = defineProps<{
		surveyJson: any;
		activeSurveyId: string;
		surveyData: any;
		noNav: boolean;
		noScroll: boolean;
		valid: number;
		isPlot?: boolean;
		index?: number
	}>();

	StylesManager.applyTheme("bootstrap");

	// const survey = shallowRef(null);
	var survey = new Model(props.surveyJson);

	onMounted(() => {
		// function initSurvey () {
		// survey = new Model(props.surveyJson);
		survey.fromJSON(props.surveyJson);

		// survey.onComplete.add(alertResults);
		survey.onPartialSend.add(surveyNext);
		survey.onComplete.add(surveyComplete);
		survey.onShowingPreview.add(surveyNext);
		survey.showPreviousButton = false;

		// const survey = new SurveyVue.SurveyModel(json);
		survey.onValueChanged.add(function (sender, options) {
			if (options.name === "question11" && options.value.indexOf("complex") !== -1) {
				// Deselect all other options if "Other" is selected
				const otherIndex = options.value.indexOf("complex");
				if (otherIndex > -1 && survey) {
					let data = survey?.data;
					if (otherIndex == (options.value.length - 1)) {
						data.question11 = ["complex"];
					}
					else {
						data.question11.splice(otherIndex, 1);
					}
					survey.data = (data);
				}
			}
		});

		if (props.noScroll) {
			survey?.onScrollingElementToTop.add((sender, options) => {
				// Remember scroll position before value change
				options.cancel = true;
			});
		}

		// Restore survey results
		console.log("init WSurvey" + survey.title);
		setSurveyData(props.surveyData);

		// emit("update-survey", survey.data, survey.validate(), props.activeSurveyId);
	// }
	// initSurvey();
	});

	watch(
		() => pwaStore.updateItemId,
		(newVal, oldVal) =>{
			if ((newVal == props.activeSurveyId) ||
				(newVal + "_walk" == props.activeSurveyId) ||
				(newVal + "_pre" == props.activeSurveyId)) {
				setSurveyData(props.surveyData);
			}
		}
	);
	// watch(
	// 	() => survey?.data,
	// 	(newVal, oldVal) => {
	// 		console.log("(WATCH) Survey Data Updated (" + survey.title + "):", newVal);
	// 		console.log(oldVal);
	// 	},
	// 	{ deep: true, immediate: false, flush: "post" }
	// );

	watch(() => props.surveyJson, (newSurveyJson: any) => {
		survey?.setJsonObject(newSurveyJson);
		console.log("saw a change to json");
	});

	function setSurveyData (woodlandSurvey: any) {
		if (woodlandSurvey == null) return;
		// Restore survey results

		if (woodlandSurvey && survey) {
			survey.data = woodlandSurvey;
			if (woodlandSurvey.pageNo) {
				survey.currentPageNo = woodlandSurvey.pageNo;
			}
		}
	}

	// actioned on survey complete button
	const alertResults = (sender: any) => {
		alert("Your woodland survey is complete. You may still edit your answers until the survey itself is submitted. Simply return to this page.");
	};

	function nextPage () {
		if (survey.currentPage == "all" ||
			survey.currentPage == "preview-page" ||
			pwaStore.updateInProgress) return;
		survey.nextPage();
		survey.render();

	}

	// SAVE SURVEY ROUTES

	// actioned on next button
	const surveyNext = (sender: any) => {
		if (!survey) return;
		saveSurveyResults();
	};

	// actioned when survey complete button is hit
	const surveyComplete = (sender: any) => {

		surveyNext(sender);
	};

	const emit = defineEmits(["update-survey"]);
	async function saveSurveyResults () {
		emit("update-survey", survey?.data, survey?.validate(), props.activeSurveyId);
	}

	const goToError = computed(() => {

		// if (!survey["isShowingPreview"])
		// 	return props.survey.validate();

		let hasError = false;
		let currentPage = null;
		let X = null;
		survey?.pages.forEach(page => {
			if (!hasError) {
				if (page.visible && !(page.name == "all")) page.questions.forEach(question => {
					// console.log(question.isVisible);
					if (question.isVisible && question.isRequired &&
						(question.isEmpty() || question.hasErrors()) &&
						(question.renderAs != "image")) {
						hasError = true;
						currentPage = page;
						// Navigate to the first page with an error

						// if (props.isPlot && props?.index) console.log("HINT: need to correct Plot (" + (props.index + 1) + "): " + currentPage.title);
						// if (page.name == "all") return null;
						X =  currentPage.title;
					}
				});
			}
		});
		return X;
	});

	function showPreview () {
		if (survey.currentPage == "all" ||
			survey.currentPage == "preview-page" ||
			pwaStore.updateInProgress) return;
		saveSurveyResults();
		survey.showPreview();
	}
</script>

<style>

.noUi-origin{
	width:10%;
}
.sd-small-btn {
	max-width: 100px;
	padding: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px)))) calc(3*(var(--sjs-base-unit, var(--base-unit, 8px))));
}
</style>
