import router from "@/router";
import { defineStore } from "pinia";
import { getResponseError } from "@/helpers/api";
import { useAlertStore } from "@/stores/alert";
import { usePwaStore } from "./pwa";
import AuthService from "@/services/AuthService";
import type { AuthStore } from "@/types/stores";
import axios, { AxiosError } from "axios";
import Bugsnag from "@bugsnag/js";
import { Modal } from "bootstrap";

export const useAuthStore = defineStore("auth", {
	state: (): AuthStore => {
		return {
			user: null,
			error: null,
			loading: false
		};
	},
	actions: {
		/**
		 * Work out the user's authentication state
		 * @returns {Promise<boolean>} True if logged in on the server or false
		 * if not.
		 */
		async state (): Promise<boolean> {
			const authService = new AuthService();
			try {
				await authService.state();
			} catch (error: unknown) {
				if (axios.isAxiosError(error)) {
					const axError = error as AxiosError;
					if (axError.response?.status === 401) {
						return false;
					} else {
						throw error;
					}
				}
			}
			return true;
		},
		async login (payload: any) {
			const authService = new AuthService();
			const alertStore = useAlertStore();
			try {
				// Ensure the CSRF cookie is up to date before logging in
				await authService.getCsrfCookie();
				const response = await authService.login(payload);
				this.user = response.data.user;
				// this.setBrowserData();
				alertStore.clearAll();
				await this.getCurrentUser();
				if (!this.user.email_verified_at) alertStore.info("Check your email to verify your email address. You can resend the verification email from your profile");
				await router.push("/panel/dashboard");
			} catch (error:any) {

				if (error.response) {
					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.error("Server responded with an error:", error.response.data);

					// Assuming Laravel returns an error message in the response
					this.error = error.response.data.message;
				} else if (error.request) {
					// The request was made but no response was received
					console.error("No response received from the server:", error.request);
				} else {
					// Something happened in setting up the request that triggered an Error
					// @TODO: seems to be landing here for incorrect credentials when it should be catching the 422 (unprocessable content error)
					// Update: This is now fixed in resources/js/plugins/axios.ts so the 422 is now being handled
					// This code can probably be removed now?
					console.error("An error occurred while setting up the request:", error.message);
					alertStore.error("User credentials don't match, please re-enter your username and password.");
					return;
				}
				alertStore.error(getResponseError(error));
			}
		},
		async register (payload: any) {
			const authService = new AuthService();
			const alertStore = useAlertStore();
			// Ensure the CSRF cookie is up to date before logging in
			await authService.getCsrfCookie();
			try {
				const response = await authService.registerUser(payload);
				// At this point the user does not have a session but can login
				// so redirect them to the login screen with a success message
				console.log("Please check your email and follow the link to verify your email address");
				alertStore.success("Please check your email and follow the link to verify your email address.");
				await router.push("/panel/dashboard");
			} catch (error) {
				console.log("i errored:");
				alertStore.error(getResponseError(error));
			}
		},

		async getCurrentUser () {
			// if (this.user == null) return null;
			console.log("GET current user");

			this.loading = true;
			const authService = new AuthService();
			try {
				const response = await authService.getCurrentUser();
				this.user = response.data.data;
				this.loading = false;
			} catch (error) {
				this.loading = false;
				this.user = null;
				this.error = getResponseError(error);
			}
			return this.user;

			// this.loading = true;
			// const authService = new AuthService();
			// const alertStore = useAlertStore();
			// // if (this.user == null) return this.user;
			// try {
			// 	const response = await authService.getCurrentUser();
			// 	this.user = response.data.data;
			// 	if (this.user.type == "owner")
			// 		if (this.user.clients.length == 0) {
			// 			const result : any[] = [];

			// 			const client = {
			// 				id: uuidv4(),
			// 				estates: result,
			// 				name: this.user.full_name,
			// 				created_at: new Date(),
			// 				updated_at: new Date(),
			// 				user_id: this.user.id
			// 			};
			// 			const estate = {
			// 				id: uuidv4(),
			// 				created_at: new Date(),
			// 				sites: [],
			// 				name: this.user.full_name,
			// 				updated_at: new Date(),
			// 				client_id: client.id };
			// 			client.estates.push(estate);
			// 			this.user.clients.push(client);
			// 		}
			// 	this.loading = false;
			// } catch (error) {
			// 	this.loading = false;
			// 	this.user = null;
			// 	this.error = getResponseError(error);
			// 	alertStore.error(getResponseError(error));
			// }
			// return this.user;
		},

		async checkSession () {
			try {
				const response = await axios.get("/api/check-session", { withCredentials: true });
				// console.log(response.data);
				if (response.status === 200) {
					console.log("Session is active.");
					// Session is valid
					return true;

				} else {
					(console.log(response.status));
					return false;
				}

			} catch (error: any) {
				if (error.response && error.response.status === 419) {
					console.log("Session has expired.");
					// Trigger re-authentication, e.g., call getUser function or redirect to login
					// getCurrentUser();
					// Session has expired
				}
				console.error("Error checking session:", error);
				return false;
			}
		},

		async syncCurrentUser (force? : boolean): Promise<void> {
			// TODO: 1. takes a long time. 2. sends but doesnt receive data regardless of device

			console.log("Sync current user");
			this.loading = true;

			// Ensure the session is active before proceeding. It might have
			// timed out in 2 cases:
			// 1. while the user was offline, so we want to renew it now they are
			// coming online.
			// 2. while the user was online, which threw an error in the API layer
			// and triggered a sync to recover. Again, we want to renew it to ensure
			// we recover successfully,
			const authService = new AuthService();
			await authService.getCsrfCookie();

			const pwaStore = usePwaStore();
			pwaStore.startUpdate();

			try {
				// abort is the user is not valid in the FE and send to log in
				if (this.user == null) {
					await router.push("/login");
					throw new Error("syncCurrentUser called, but this.user is null - redirected to login.");
				}
				// abort if the user has manually set their device to offline
				else if (pwaStore.manuallyOffline()) throw new Error("You have manually disabled syncing");
				// otherwise go ahead and start sync to the DDB
				// sends the data to the DB
				// straight up success
				else {
					// check if we are forcing an update even if the server data has been edited more recently (we have an older synced_at timestamp for the FE data)
					if (force === undefined) {
						console.log("force not defined");
						await axios.patch(route("api.user.update", { id: this.user.id }), this.user, {
							params: { full: true } })
							.then((res) => {
								console.log(res.data.message);
							});
					}
					else {
						console.log("force defined: " + force);
						console.log(this.user);
						// data returned is not deep, only goes up to clients
						await axios.patch(route("api.user.update", { id: this.user.id }), this.user, {
							params: { full: true, force: force } })
							.then((res) => {
								console.log("result");
								console.log(res.data);
							});
					}
					// success but will overwrite some data in the UI:

					this.loading = false;
					pwaStore.requireSync = false;
				}
			} catch (error) {
				console.error("syncCurrentUser failed:", error);
				this.loading = false;
				// why was this false?! v
				pwaStore.requireSync = true;
				this.error = getResponseError(error);
				// We need to handle this error very carefully, because syncCurrentUser
				// is responsible both for syncing offline changes AND recovering after
				// an error elsewhere. So if we're here, it has failed and we are in
				// trouble! We need to inform the user and block the UI to stop them
				// doing more work, which can be done with a modal.
				if (Bugsnag.isStarted()) Bugsnag.notify(error as Error);
				const element = document.getElementById("modal-sync-error");
				if (element) {
					const modal = new Modal(element, {});
					modal.show();
				}
			}
			// regardless of success, gets the current user from the db
			pwaStore.requireSync = false;
			this.getCurrentUser();
			console.log("finally user is:");
			console.log(this.user);
			pwaStore.endUpdate(this.user.id, true);

		},
		logout () {
			return new Promise<void>((resolve, reject) => {
				const alertStore = useAlertStore();
				alertStore.clearAll();
				const authService = new AuthService();

				// Put all the logic here to complete logging out in the frontend
				const completeLogout = () => {
					// this.clearBrowserData();
					this.user = null;
					if (router.currentRoute.value.name !== "login") {
						router.push({ path: "/login" });
						console.log("logout...");
					}
				};

				return authService
					.logout()
					.then(() => {
						completeLogout();
						resolve();
					})
					.catch((err) => {
						// If logout failed with a 419 or a 401 then the session
						// has already expired on the server, so just complete
						// logging out as above.
						// (We should not get here with 419 because logout route
						// is excluded from the VerifyCsrfMiddleware.php)
						const status: number | null = err?.response?.status;
						if (status !== null && [401, 419].includes(status)) {
							completeLogout();
							resolve();
						} else {
							alertStore.error(getResponseError(err));
							reject(err);
						}
					});
			});
		},
		// setBrowserData () { // todo REMOVE localservice b/c using persistent state!!
		//     window.localStorage.setItem("currentUser", JSON.stringify(this.user));
		// },
		// clearBrowserData () {
		//     window.localStorage.removeItem("currentUser");
		// },
		hasAbilities (abilities: any) {
			return Object.prototype.hasOwnProperty.call(this.user, "abilities") && !!this.user.abilities.find((ab: any) => {
				if (ab.name === "*") {
					return true;
				}
				if (typeof abilities === "string") {
					return ab.name === abilities;
				}
				return !!abilities.find((p: any) => {
					return ab.name === p;
				});
			});
		},

		hasAllAbilities (abilities: any) {
			let isAvailable = true;
			if (Object.prototype.hasOwnProperty.call(this.user, "abilities")) {
				this.user.abilities.filter((ab: any) => {
					const hasContain = !!abilities.find((p: any) => {
						return ab.name === p;
					});
					if (!hasContain) {
						isAvailable = false;
					}
				});
			}

			return isAvailable;
		}
	},
	getters: {
		isAdmin: (state: AuthStore) => {
			return state.user ? state.user.is_admin : false;
		},
		loggedIn: (state: AuthStore) => {
			return !!state.user;
		},
		guest: (state: AuthStore) => {
			return state.user == null;
		}
	},
	persist: true
});
