/* eslint-disable no-mixed-spaces-and-tabs */

export const plotSurveyJson = {
//  };

	// export const plotSurveyJson2 = {
	"title": "10m Plot Survey",
	"logoPosition": "right",
	"pages": [
	 {
			"name": "woodlandLocation",
			"elements": [
		 {
		  "type": "panel",
		  "name": "panel1",
		  "elements": [
		   {
							"type": "radiogroup",
							"name": "question0x",
							"defaultValueExpression": "",
							"isRequired": true,
							"title": "GPS or grid reference?",
				  			"description": "GPS location for this plot is displayed below based on your selection of the plot marker on the map*. If you are happy with this location, please continue. You may also change to a grid reference location for your plot.\n\n* Users that added plots prior to the release of version 2 of the Woodland Condition Assessment application should carefully check that these coordinates are correct.",
							"choices": [
			 {
			  "value": "gps",
			  "text": "GPS"
			 },
			 {
			  "value": "gridref",
			  "text": "Grid reference"
			 }
							]
		   },
		   {
							"type": "text",
							"name": "question12",
							"visibleIf": "{question0x} = 'gridref'",
							"defaultValueExpression": "",
							"isRequired": true,
							"title": "Enter grid reference"
		   },
		   {
							"type": "text",
							"name": "question10lat",
							"visibleIf": "{question0x} = 'gps'",
							// "defaultValueExpression": "",
							"isRequired": true,
							"title": "Latitude",
							"inputType": "number",
							"min": -90,
							"max": 90
		   },
		   {
							"type": "text",
							"name": "question10lon",
							"visibleIf": "{question0x} = 'gps'",
							// "defaultValueExpression": "",
							"isRequired": true,
							"title": "Longitude",
							"inputType": "number"
		   },
		   {
							"type": "text",
							"name": "location_descriptor",
							"title": "Description",
							"description": "Use this box to describe the location of your plot (e.g. use what-three-words)"
		   }
		  ]
		 }
			],
			"title": "Plot location"
	 },
	 {
	  "name": "page2",
	  "visibleIf": "{survey_type} = 'wca'",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question17",
					"hideNumber": true,
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>        W1 Broadleaved, mixed and yew      </button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>This is vegetation dominated by trees that are more than 5m high when mature, which form a distinct, although sometimes open canopy cover of greater than 25%. It includes stands of both native and non-native broadleaved tree species and Yew Taxus Baccata, where the percentage cover of these trees in the stand exceeds 20% of the total cover of trees present.</p>" +
						"<p><strong>Inclusions</strong><br/>Ancient or recent woodland and either semi-natural or planted. Recently felled broadleaved, mixed and Yew woodland there there is a clear indication that it will return to woodland. Carr (woody vegetation on fens and bog margins).</p>" +
						"<p><strong>Exclusions</strong><br/>Hedgerows (see h2). Scrub (see h3 or 10). Woodlands that are dominated by conifer trees with less than 20% of the total cover provided by broadleaved or Yew trees (see w2).</div></div></div><div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseTwo\" aria-expanded=\"false\" aria-controls=\"flush-collapseTwo\"><i class=\"bi-info-circle me-2\"></i>        W2 Coniferous woodland      </button>    </h2>    <div id=\"flush-collapseTwo\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingTwo\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>This is vegetation dominated by trees that are more than 5m high when mature, which form a distinct, although sometimes open canopy which has a cover of greater than 20%, with stands of both native and non-native coniferous tree species (with the exception of Yew, <em>Taxus Baccata</em>) where the percentage cover of these trees in the stand exceeds 80% of the total cover of the trees present.</p>" +
						"<p><strong>Landscape and Ecological Context</strong><br/>Scots Pine, <em>Pinus Sylvestris</em>, is the only pine tree that is native to the UK, and forms native woodland only in Scotland. Semi-natural woods of Scots Pine are normally called native pinewoods. The majority of coniferous woodland in the UK are plantations of species that are either not native to the UK or to sites on which they occur.<p>" +
						"<p><strong>Inclusions</strong><br/>Recently felled coniferous woodland where there is a clear indication that it will return to woodland.</p>" +
						"<p><strong>Exclusions</strong><br/>Woodlands that are made up of broadleaved, Yew and conifer trees with less than 80% of the total cover provided by conifer trees.</p></div></div></div></div>"
	   },
	   {
					"type": "radiogroup",
					"name": "question1",
					"defaultValue": "",
					"isRequired": true,
					"title": "What is the predominant woodland type within survey plot. If woodland type can be distinguished, tick one from the two woodland type options below for each plot.",
					"choices": [
		 {
		  "value": "b",
		  "text": "Broadleaved, mixed and yew woodland"
		 },
		 {
		  "value": "c",
		  "text": "Coniferous woodland"
		 }
						//  {
						//   "value": "b/c",
						//   "text": "Neither type of woodland is more dominant"
						//  }
					]
	   }
	  ],
	  "title": "Woodland type"
	 },
	 {
	  "name": "page3",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question25",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">" +
					"<h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Wild, domestic and feral herbivore damage</button></h2>" +
					"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
					"<p>Browsing pressure can be recognised as follows:" +
					"<ul><li>Browse line: Lower branches and shoots of trees and shrubs are browsed back so that leaves no longer occur within reach of livestock, giving a 'topiary' appearance</li>" +
					"<li>Bark stripping: for example, deer antler rubs and squirrel damage</li>" +
					"<li>Shoots bitten off, damaged or absent shoot tips: Includes damage to shoots present at the base, on the trunk or on the lower branches of trees and taller shrubs</li>" +
					"<li>'Topiary' appearance of shrubs</li>" +
					"<li>Well-used deer tracks</li>" +
					"<li>Grazed lawn: if ground vegetation is heavily browsed</li>" +
					"</ul></p>" +
					"<p>Browsing pressure is considered to be <em>significant</em> where >20% of vegetation visible within each survey plot shows any of these forms of damage.</p>" +
					"</div></div></div></div>"
	   },
	   {
					"type": "boolean",
					"name": "question2",
					"defaultValueExpression": "",
					"isRequired": true,
					"title": "Is there evidence of significant browsing pressure within the survey plot?"
	   }
	  ],
	  "title": "2|B Wild, domestic and feral herbivore damage"
	 },
	 {
	  "name": "page5",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question7",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Invasive non-native plant species (INNS)</button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\"><p>Invasive species cover is calculated as a percentage of the total area of the woodland. These percentages are used to assign a score for each woodland. Note that because Rhododendron and Cherry Laurel are regarded as aggressive colonisers, their presence at any amount leads to an unfavourable score.</p><p>Please also refer to the <a href=\"http://www.nonnativespecies.org/home/index.cfm\" target=\"_blank\">GB non-native species secretariat website</a>.</p></div></div></div></div>"
	   },
	   {
					"type": "boolean",
					"name": "question15",
					"title": "Are any invasive non-native species present in this plot?",
					"defaultValueExpression": "",
					"isRequired": true
	   },
	   {
					"type": "imagepicker",
					"name": "question3",
					"visibleIf": "{question15} = true",
					"defaultValueExpression": "",
					"title": "Are any of the following invasive non-native plants present within the survey plot?",
					"description": "Check any species that are present within this plot and scroll down to enter the % cover per species identified.",
					"isRequired": true,
					"choices": [
		 {
		  "value": "lysichiton_americanus",
		  "text": "American Skunk Cabbage (Lysichiton Americanus) - Foul-smelling yellow flowers shiny green leaves",
		  "imageLink": "/invasive_01_american_skunk.jpg"
		 },
		 {
		  "value": "impatiens_glandulifera",
		  "text": "Himalayan Balsam (Impatiens Glandulifera) - Hollow stems and pink flowers, dies down in winter",
		  "imageLink": "/invasive_02_himalayan_balsam.jpg"
		 },
		 {
		  "value": "fallopia_japonica",
		  "text": "Japanese Knotweed (Reynoutria Japonica) - sRed stems and white flowers, dies down in winter",
		  "imageLink": "/invasive_03_japanese_knotweed.jpg"
		 },
		 {
		  "value": "prunus_laurocerasus",
		  "text": "Cherry Laurel (Prunus Laurocerasus) - Evergreen shrub with shiny green leaves",
		  "imageLink": "/invasive_04_laurel.jpg"
		 },
		 {
		  "value": "gaultheria_shallon",
		  "text": "Shallon (Gaultheria Shallon) - Low-growing woody evergreen, bell-shaped flowers",
		  "imageLink": "/invasive_05_shallon.jpg"
		 },
		 {
		  "value": "symphoricarpos_albus",
		  "text": "Snowberry (Symphoricarpos Albus) - Dense twiggy shrub with abundant white berries",
		  "imageLink": "/invasive_06_snowberry.jpg"
		 },
		 {
		  "value": "lamiastrum_galeobdolon",
		  "text": "Variegated Yellow Archangel (Lamiastrum Galeobdolon subsp. Argentatum) - Looks like nettle, but with white-blotched leaves",
		  "imageLink": "/invasive_07_archangel.jpg"
		 },
		 {
		  "value": "rhododendron_ponticum",
		  "text": "Rhododendron (Rhododendron Ponticum) - Evergreen shrub with leathery green leaves",
		  "imageLink": "/invasive_08_rhod.jpg"
		 },
		 {
		 "value": "other",
		 "text": "Other invasive or Schedule 9 species (detail below)",
		 "imageLink": "/invasive_other.jpg"
		 }
					],
					"colCount": 1,
					"imageFit": "cover",
					"minImageHeight": 105,
					"maxImageWidth": 200,
					"maxImageHeight": 105,
					"showLabel": true,
					"multiSelect": true
	   },
	   {
					"name": "question3_1",
					"title": "Record the percent of this plot covered by American Skunk Cabbage",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"visibleIf": "{question15} and ({question3} anyof ['lysichiton_americanus'])",
					"type": "nouislider",
					"min": 1,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_2",
					"visibleIf": "{question15} and ({question3} anyof ['impatiens_glandulifera'])",
					"title": "Record the percent of this plot covered by Himalayan Balsam",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 1,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_3",
					"visibleIf": "{question15} and ({question3} anyof ['fallopia_japonica'])",
					"title": "Record the percent of this plot covered by Japanese Knotweed",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 1,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_4",
					"visibleIf": "{question15} and ({question3} anyof ['prunus_laurocerasus'])",
					"title": "Record the percent of this plot covered by Cherry Laurel",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_5",
					"visibleIf": "{question15} and ({question3} anyof ['gaultheria_shallon'])",
					"title": "Record the percent of this plot covered by Shallon",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_6",
					"visibleIf": "{question15} and ({question3} anyof ['symphoricarpos_albus'])",
					"title": "Record the percent of this plot covered by Snowberry",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_7",
					"visibleIf": "{question15} and ({question3} anyof ['lamiastrum_galeobdolon'])",
					"title": "Record the percent of this plot covered by Variegated Yellow Archangel",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_8",
					"visibleIf": "{question15} and ({question3} anyof ['rhododendron_ponticum'])",
					"title": "Record the percent of this plot covered by Rhododendron",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"name": "question3_9",
					"visibleIf": "{question15} and ({question3} anyof ['other'])",
					"title": "Record the total percent of this plot covered by other invasive or Schedule 9 species",
					"description": "If the value is 50%, you will still need to tap the slider.",
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range"
	   },
	   {
					"visibleIf": "{question15} and ({question3} anyof ['other'])",
					"type": "comment",
					"name": "innsnotes",
					"isRequired": true,
					"title": "Indicate any other invasive or Schedule 9 species:"
	   }
	  ],
	  "title": "3|C Invasive non-native plant species"
	 },
	 {
	  "name": "page4",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question21",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Number of native tree and shrub species</button></h2><div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
					"<p>Record the main tree and shrub species present in the upper canopy (>5m) within this plot. Not all species listed are native to all parts of the UK. Commonly found non-native tree species should also be recorded if present.</p></div></div></div></div>"
	   },
	   {
					"type": "boolean",
					"name": "question16",
					"title": "Are native tree and/or shrub species present?",
					"isRequired": true
	   },
	   {
					"visibleIf": "{question16} = true",
					"type": "checkbox",
					"name": "question4",
					"isRequired": true,
					// "showNoneItem": true,
					"title": "Native trees and shrubs",
					// "defaultValue": [
					//  "none"
					// ],
					"choices": [
						"Alder - Buckthorn",
						"Alder - Common",
						"Ash",
						"Aspen ",
						"Beech",
						"Birch - Downy",
						"Birch - Silver",
						"Blackthorn",
						"Box ",
						"Bramble ",
						"Broom ",
						"Butcher's broom",
						"Cherry Wild/gean ",
						"Cherry Bird",
						"Crab Apple ",
						"Dog Rose ",
						"Dogwood",
						"Elder ",
						"Elm - English",
						"Elm - Small-leaved",
						"Elm - Smooth-leaved",
						"Elm - Wych",
						"Field Maple ",
						"Field rose",
						"Gorse ",
						"Guelder rose",
						"Hawthorn ",
						"Hawthorn - Midland",
						"Hazel ",
						"Holly ",
						"Hornbeam ",
						"Juniper",
						"Lime - Common ",
						"Lime - Small-leaved",
						"Lime - Large-leaved ",
				  		"Poplar - Black",
				  		"Poplar- Grey",
						"Oak - Pedunculate/common ",
						"Oak - Sessile",
						"Oak - General",
						"Purging buckthorn",
						"Rowan ",
						"Spindle",
						"Spurge laurel",
						"Wayfaring tree",
						"Whitebeam (all native spp)",
						"Wild privet",
						"Wild service tree ",
						"Willow - Almond",
						"Willow - Bay",
						"Willow - Crack",
						"Willow - Eared",
						"Willow - Goat",
						"Willow - Grey",
						"Willow - Montane (all) ",
						"Willow - Purple willow",
						"Willow - White",
						"Willow - Osier",
						"Willow - General",
						"Yew "
					],
					"colCount": 2
	   },
	   {
					"type": "boolean",
					"name": "question18",
					"title": "Are non-native tree and/or shrub species present?",
					"isRequired": true
	   },
	   {
					"visibleIf": "{question18} = true",
					"type": "checkbox",
					"name": "question9",
					"isRequired": true,
					// "showNoneItem": true,
					"title": "Non-native species",
					// "defaultValue": [
					//  "none"
					// ],
					"choices": [
						"Cedar spp.",
						"Cedar - Western red",
						"Chestnut - Horse",
						"Chestnut - Sweet",
						"Coast redwood",
						"Fir - Douglas",
						"Fir - European silver",
						"Fir - Grand",
						"Fir - Noble",
						"Italian alder",
						"Larch - European",
						"Larch - Japanese",
						"Lawson's cypress",
						"Maple - Norway",
						"Maple - Silver",
						"Oak - Red",
						"Oak - Turkey",
						"Oak- Holm",
						"Pine - Corsican",
						"Pine - Lodgepole",
						"Pine - Maritime",
						"Pine - Scots",
						"Raoul/Rauli/Roble",
						"Spruce - Norway",
						"Spruce - Sitka",
						"Sycamore",
						"Walnut - Black",
						"Walnut - Common",
						"Western hemlock",
						"Wild pear ",
						"Wild plum",
						"Other Conifer Species",
						"Other Broadleaved Species"
					],
					"colCount": 2
	   }
	  ],
	  "title": "4|D Number of native tree and shrub species",
	  "description": "Using lists provided, identify within the survey plots the main native tree and shrub species present in the upper storey (>5m) and under storey (up to 5m) layers, including young trees and shrubs. Tick if present in plot."
	 },
	 {
	  "name": "page6",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question23",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"> <i class=\"bi-info-circle me-2\"></i>       Cover of native trees (in this plot)      </button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\"><p>The abundance of native tree species in upper (>5m) and understorey (up to 5m) layers including young trees and shrubs.</p>" +
					"<p>You may find the CanopyCover (CanopyCapture on Windows) application (<a href=\"https://play.google.com/store/apps/details?id=com.heaslon.canopycover\" target=\"_blank\">Android</a>, <a href=\"https://appsonwindows.com/apk/5197531/\" target=\"_blank\">Windows</a>) useful for this question.</p>" +
					"</div></div></div></div>"
	   },
	   {
					"type": "nouislider",
					"name": "question5",
					"isRequired": true,
					"title": "Record the percentage of the canopy cover in the upper storey (>5m) layer that is made up of native tree species within this survey plot's boundaries.",
					"inputType": "range",
					"min": 0,
					"max": 100,
					"step": 1
	   },
	   {
					"type": "boolean",
					"name": "question5b",
					"title": "Is there an understorey (<5m) layer present?",
					"isRequired": true
	   },
	   {
					"type": "nouislider",
					"name": "question5c",
					"isRequired": true,
					"visibleIf": "{question5b} = true",
					"title": "Record the percentage of the canopy cover in the understorey (<5m) layer that is made up of native tree species within this survey plot's boundaries.",
					"inputType": "range",
					"min": 0,
					"max": 100,
					"step": 1
	   }
	  ],
	  "title": "5|E Cover of native tree and shrub species"
	 },
	 {
	  "name": "page8",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question28",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Woodland regeneration</button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>Record the number of classes of native (include naturalised broadleaved species) and non-native trees in the three " +
						"classes:<ul><li>4-7cm DBH,</li><li>saplings</li><li>seedlings or advanced coppice regrowth.</li></ul>" +
						"This indicator measures regeneration potential of the woodland by considering three classes: seedlings, saplings, and young trees of 4-7cm DBH. " +
						"All three classes would fall in the ‘young’ category of the ‘age distribution of trees’ indicator, but the regeneration " +
						"indicator gathers additional information by considering regeneration potential - if seedlings, saplings and young " +
						"trees are all present that means natural regeneration processes are happening.</p>" +
						"<p>Note that DBH = (Diameter at Breast Height, recommended that this is 1.3m above ground level)</p></div></div></div></div>"
	   },
	   {
					"type": "checkbox",
					"name": "question8",
					"isRequired": true,
					"showNoneItem": true,
					"title": "Record the classes of native/non-native regeneration present within this plot:",
					"choices": [
		 {
		  "value": "Item 1",
		  "text": "Young trees: 4-7cm DBH"
		 },
		 {
		  "value": "Item 2",
		  "text": "Saplings: young trees that have not reached 4-7 cm DBH"
		 },
		 {
		  "value": "Item 4",
		  "text": "Seedlings (from seeds that have germinated this year) or advanced coppice growth (sprouting from stumps)"
		 }
					]
	   }
	  ],
	  "title": "8|G Woodland regeneration"
	 },
	 {
			"name": "page9a",
			"visible": true,
			"elements": [
		 {
					  "type": "html",
					  "renderAs": "image",
					  "name": "question26",
					  "html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\"><div class=\"accordion-item\">" +
						"<h2 class=\"accordion-header\" id=\"flush-headingOne\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>Tree Health</button></h2>" +
						"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>Tree health indicators include:" +
						"<ul><li>rapid rate of tree mortality above natural or background levels;</li>" +
						"<li>large proportion of crown dieback across a stand of trees;</li>" +
						"<li>presence of significant tree diseases.</li></ul></p>" +
						"<p>Tree death is a necessary part of ecosystem function, and as a measure of tree health, mortality is about capturing rapid loss.</p>" +
						"</div></div></div>" +
						"<div class=\"accordion-item\"><h2 class=\"accordion-header\" id=\"flush-headingTeo\"><button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseTwo\" aria-expanded=\"false\" aria-controls=\"flush-collapseTwo\"><i class=\"bi-info-circle me-2\"></i>Pests and diseases</button></h2><div id=\"flush-collapseTwo\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingTwo\" data-bs-parent=\"#accordionFlushExample\">" +
						"<div id=\"flush-collapseTwo\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingTwo\" data-bs-parent=\"#accordionFlushExample\">" +
						"<div class=\"accordion-body\">" +
						"<table><thead><th>Disease/Pest</th><th>Risk level</th></thead><tbody><tr><td>Acute/Chronic Oak Decline</td><td>High</td></tr><tr><td>Anoplophora chinensis</td><td>High</td></tr><tr><td>Anoplophora glabripennis</td><td>High</td></tr><tr><td>Ash Dieback</td><td>High</td></tr><tr><td>Asian Longhorn beetle</td><td>High</td></tr><tr><td>Bronze Birch borer</td><td>High</td></tr><tr><td>Canker</td><td>Low</td></tr><tr><td>Cryphonectria parasitica</td><td>High</td></tr><tr><td>Dendroctinus micans</td><td>Low</td></tr><tr><td>Emerald Ash borer</td><td>High</td></tr><tr><td>Gibrella circinata</td><td>High</td></tr><tr><td>Horse Chestnut Bleeding Canker</td><td>High</td></tr><tr><td>Horse Chestnut leaf miner</td><td>Low</td></tr><tr><td>Ips amitinus</td><td>High</td></tr><tr><td>Ips duplicatus</td><td>High</td></tr><tr><td>Ips typographus</td><td>High</td></tr><tr><td>Oak Processionary Moth</td><td>Low</td></tr><tr><td>Phytophthora lateralis</td><td>High</td></tr><tr><td>Phytophthora kernoviae</td><td>High</td></tr><tr><td>Phytophthora ramorum</td><td>High</td></tr><tr><td>Phytophthora austrocedrae</td><td>High</td></tr><tr><td>Phytophthora spp</td><td>High</td></tr><tr><td>Pine Lappet Moth</td><td>High</td></tr><tr><td>Pine Processionary Moth</td><td>High</td></tr><tr><td>Red Band Needle Blight</td><td>High</td></tr><tr><td>Sawyer Beetle</td><td>Low</td></tr><tr><td>Tomicus piniperda</td><td>Low</td></tr><tr><td>Weevils</td><td>High</td></tr><tr><td></tbody></table></div></div></div></div></div></div>"
		 },
		 {
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range",
					  "name": "question9a",
					  "title": "What % of trees within this plot are dead?",
					  "description": "If the value is 50%, you will still need to tap the slider."
		 },
		 {
					"type": "nouislider",
					"min": 0,
					"max": 100,
					"step": 1,
					"isRequired": true,
					"inputType": "range",
					  "name": "dieback",
					  "title": "What % of trees within this plot have crown dieback?",
					  "description": "If the value is 50%, you will still need to tap the slider."
		 },
		 {
					"type": "radiogroup",
					"name": "question9b",
					"title": "Tick only one below for this plot to indicate the evidence of pests and disease",
					"isRequired": true,
					"defaultValueExpression": "",
					"choices": [
						{
							"value": "3",
							"text": "No pests and diseases"
						},
						{
							"value": "2",
							"text": "Low-risk pests or diseases present"
						},
						{
							"value": "1",
							"text": "High-risk pests or diseases present"
						}
					]
				}
			],
			"title": "9|H Tree health"
	   },
	 {
	  "name": "page9",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question29",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>        Vegetation and ground flora indicators</button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\"><p>Surveys should be undertaken within an April to October survey window in order for an accurate assessment of the sites ground vegetation. Lists of ancient woodland indicator plants vary in different parts of the UK, but they all share in common the fact that the more of these species found in a wood, the more likely it is to be ancient woodland. Strongly characterised means at least several ancient woodland specialist species are noted as occasional, frequent or abundant across the ground vegetation. In many instances these will enable identification of NVC (National Vegetation Classification) communities.</p>" +
					"<span class=\"me-2 btn\">" +
					"	<a href=\"\" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#nvc_modal_\">Use our NVC key tool if you need further help answering this question</a>" +
					"</span>" +
					"</div></div></div></div>"
	   },
	   {
					"type": "boolean",
					"name": "question10a",
					"title": "Is the ground layer strongly characterised by ancient woodland specialists?",
					"defaultValueExpression": "",
					"isRequired": true
	   },
	   {
					"type": "boolean",
					"name": "question10b",
					"title": "Is there a recognisable woodland NVC community at ground layer?",
					"defaultValueExpression": "",
					"isRequired": true
	   },
	   {
					"type": "comment",
					"name": "nvcnotes",
					"title": "Use this space to note any ancient woodland indicators or NVC community present (optional):"
	   },
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question29b",
					"html": "	<a href=\"\" type=\"button\" data-bs-toggle=\"modal\" data-bs-target=\"#nvc_modal_\">Use our NVC key tool if you need help answering this question</a>"
	   }
	  ],
	  "title": "10|I Vegetation and ground flora"
	 },
	 {
	  "name": "page10",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question13",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>        Woodland vertical structure</button>    </h2>    <div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\"><p>Vertical structure is defined as the number of canopy storeys present. Note that there might be no storeys present where the woodland has been felled.</p>"
						+ "<div class=\"row\">"
						+ "<div class=\"col-xl-3 col-sm-6 col-12 g-4 card-group\"><div class=\"card\"><img class=\"w-100\" src=\"/help/vertical_structure/11a_Upper_storey.jpg\"/>"
						+ "<div class=\"card-body\"><strong>Upper storey of canopy</strong> DBH at least 35cm (too wide to get arms around)</div></div></div>"
						+ "<div class=\"col-xl-3 col-sm-6 col-12 g-4 card-group\"><div class=\"card\"><img class=\"w-100\" src=\"/help/vertical_structure/11b_Middle_storey.jpg\"/>"
						+ "<div class=\"card-body\"><strong>Middle storey of canopy</strong> DBH at least 7cm (wider than a tin of beans)</div></div></div>"
						+ "<div class=\"col-xl-3 col-sm-6 col-12 g-4 card-group\"><div class=\"card\"><img class=\"w-100\" src=\"/help/vertical_structure/11c_Lower_storey.jpg\"/>"
						+ "<div class=\"card-body\"><strong>Lower storey of canopy</strong> DBH at <7cm (saplings and coppice regrowth)</div></div></div>"
						+ "<div class=\"col-xl-3 col-sm-6 col-12 g-4 card-group\"><div class=\"card\"><img class=\"w-100\" src=\"/help/vertical_structure/11d_Shrub_layer.jpg\"/>"
						+ "<div class=\"card-body\"><strong>Shrub layer</strong> Woody plants <1.3m tall such as Hazel and Holly</div></div></div>"
						+ "</div></div></div></div></div>"
					// TODO insert more info here
	   },
	   {
					"type": "checkbox",
					"name": "question11",
					"isRequired": true,
					// "showOtherItem": true,
					// "otherText": "Complex (made up of multiple tree heights that cannot easily be split into height bands)",
					"showNoneItem": true,
					"title": "Record all the different storeys present (check all that apply). DBH is diameter at 1.3m above base of tree.",
					"choices": [
						{
							"value": "upperlarge",
							"text": "Upper storey of canopy (too wide to get arms around / at least 35 cm DBH)"
						},
						{
							"value": "middle",
							"text": "Middle storey of canopy (wider than a tin of beans / at least 7 cm DBH)"
						},
						{
							"value": "lower",
							"text": "Lower storey of canopy (saplings and/or coppice growth >130cm tall <7 cmDBH)"
						},
						{
							"value": "shrubs",
							"text": "Shrub layer (Woody plants < 1.3m, tall such as hazel and holly)"
						},
						{
							"value": "complex",
							"text": "Complex (made up of multiple tree heights that cannot easily be split into height bands)"
						}
					]
				}
	  ],
	  "title": "11|J Woodland vertical structure"
	 },
	 {
	  "name": "page11",
	  "elements": [
	   {
					"type": "html",
					"renderAs": "image",
					"name": "question32",
					"html": "<div class=\"accordion accordion-flush\" id=\"accordionFlushExample\">  <div class=\"accordion-item\">    <h2 class=\"accordion-header\" id=\"flush-headingOne\">      <button class=\"accordion-button collapsible collapsed\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\"#flush-collapseOne\" aria-expanded=\"false\" aria-controls=\"flush-collapseOne\"><i class=\"bi-info-circle me-2\"></i>        Amount of deadwood</button>    </h2>    " +
						"<div id=\"flush-collapseOne\" class=\"accordion-collapse collapse\" aria-labelledby=\"flush-headingOne\" data-bs-parent=\"#accordionFlushExample\"><div class=\"accordion-body\">" +
						"<p>Includes the following:" +
						"<ul><li>Standing deadwood: Dead trees (at least 1m tall) that are still upright</li>" +
						"<li>Fallen deadwood: At least 20cm in diameter and 50cm long</li>" +
						"<li>Stumps: Woody base of trees, < 1m tall</li>" +
						"<li>Dead branches: deadwood sections of living trees</li></ul>" +
						"<p>Diameter should be measured at the narrowest point on the stem.</p>"
	   },
	   {
					"type": "boolean",
					"name": "question13a",
					"title": "Is standing or fallen deadwood visible in the survey plot?",
					"description": "See help section above for inclusions.",
					"defaultValueExpression": "",
					"isRequired": true
	   },
	   {
					"type": "boolean",
					"name": "question13b",
					"title": "Are large dead branches/stems and stumps or an abundance of small tree cavities visible in the survey plot?",
					"defaultValueExpression": "",
					"isRequired": true
	   }
	  ],
	  "title": "13|L Amount of deadwood"
	 },
	 {
	  "name": "page12",
	  "elements": [
	   {
					"type": "comment",
					"name": "question34",
					"title": "Please use this section to add any further survey notes for this plot:"
	   }
	  ],
	  "title": "Notes"
	 }
	],
	"calculatedValues": [
	 {
	  "name": "q1",
	  "expression": "iif({question1} empty, 'b/c', {question1}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q2",
	  "expression": "iif({question2} empty, false, {question2})",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q3LR",
	  "expression": "iif({question3},iif(count({question3})==0, false, iif({question3} contains 'rhododendron_ponticum' or {question3} contains 'prunus_laurocerasus',true,false)),false)",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q3O",
	  "expression": "iif({question3},iif(count({question3})==0, false, iif({question3} contains 'lysichiton_americanus' or {question3} contains 'impatiens_glandulifera' or {question3} contains 'fallopia_japonica' or {question3} contains 'gaultheria_shallon' or {question3} contains 'symphoricarpos_albus' or {question3} contains 'lamiastrum_galeobdolon' or {question3} contains 'other',true,false)),false)",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q4",
	  "expression": "iif({question4} empty,0,iif(count({question4})<3,1,iif(count({question4})<5,2,3)))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q5",
	  "expression": "iif(({question5}<50) or ({question5c}<50),1,iif(({question5}>80) and ({question5c}>80),3,2))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q6",
	  "expression": "iif({question6}<10,1,iif({question6}<20,3, iif({question6}<40,2,1)))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q8",
	  "expression": "iif({question8} empty, 0, count({question8}))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q9",
	  "expression": "iif({question9a}=='>25%',1,iif({question9a}=='<10%',3,iif({question9a}=='11-25%',2,0)))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q10a",
	  "expression": "{question10a}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q10b",
	  "expression": "{question10b}",
	  "includeIntoResult": true
	 },
	 {
	  "name": "q11",
	  "expression": "iif(({question11} contains 'complex') or ({question11} contains 'other'),3,iif({question11} contains 'upperlarge',1,0) + iif({question11} contains 'middle',1,0)  + iif({question11} contains 'lower',1,0) + iif({question11} contains 'shrubs',1,0))",
	  "includeIntoResult": true
	 },
	 {
	  "name": "result",
	  "expression": "{q2}+{q3}+{q4}+{q5}+{q6}+{q7}+{q8}+{q10}+{question11}+{q13}",
	  "includeIntoResult": true
	 }
	],
	"sendResultOnPageNext": true,
	"showProgressBar": "bottom",
	"showTitle": false,
	"checkErrorsMode": "onComplete",
	"pageNextText": "Save & continue",
	"completeText": "Save",
	"showPreviewBeforeComplete": "showAllQuestions",
	"widthMode": "responsive",
	"fitToContainer": true,
	"showQuestionNumbers": "off",
	"previewText": "Save & preview",
	"completedHtml": "Until you have submitted your survey, you will still be able to edit these answers. Simply return to this page or refresh.",
	"showPrevButton": true,
	"showCompletedPage": true,
	"showNavigationButtons": "none"
};
